import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import HeaderNew from '../components/header/HeaderNew';
// import VendorHeader from '../components/header/VendorHeader';
// import { useSelector } from 'react-redux';

const Layout = (props) => {
  // const {
  //     vendorAuthenticated
  //   } = useSelector((state) => state);
  return (
    //modified header component by Rishikesh Kumar Singh date-22/02/2024
    <>
      <Header />
      <main className='page-wrapper'>{props.children}</main>
      <Footer popup={props.popup} />
    </>
  );
};
export default Layout;
