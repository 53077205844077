import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { changeWarehouseStatus } from '../../../store/actions/vendor/warehouseList';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import { consiousUpdateStorageSpaceNew } from '../../../store/actions/vendor/warehouseList';
import './warehouseForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { CardLoader } from '../../helper/CustomLoader';

function UpdateStorageSpaceForm({ warehouseId, viewMood, slug, closeErrorMessage }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const [rackracksSlottedYes, setRackracksSlottedYes] = useState(true);
  const [rackracksSlottYes, setRackracksSlottYes] = useState(true);
  const floorTypesOptions = [
    { value: 'Basement', label: 'Basement' },
    { value: 'Ground Floor', label: 'Ground Floor' },
    { value: '1st Floor', label: '1st Floor' },
    { value: '2nd Floor', label: '2nd Floor' },
  ];

  const [storeForm, setStoreForm] = useState({
    storageType: 'floorarea',
    noOfShift: 0,
    palletsOnFloor: 10,
    totalAvailableSpace: 0,
    floors: [
      {
        floorType: floorTypesOptions[0].value,
        floorDimension: {
          dimension: 'SQFT',
          length: Number(),
          breath: Number(),
          height: Number(),
        },
        availableSpace: {
          dimension: 'SQFT',
          length: Number(),
          breath: Number(),
          height: Number(),
        },
        unit: 'FEET',
      },
    ],
    racks: [
      {
        name: 'binsracks',
        rackInfo: {
          quantity: Number(),
          length: Number(),
          breath: Number(),
          height: Number(),
        },
      },
      {
        name: 'shelveracks',
        rackInfo: {
          quantity: Number(),
          length: Number(),
          breath: Number(),
          height: Number(),
        },
      },
    ],
    racksSlottedYes: true,
    racksShelveYes: true,
  });

  const storeSchema = Yup.object().shape({
    noOfShift: Yup.number().required('Required'),
    floors: Yup.array(
      Yup.object().shape({
        floorType: Yup.string().required('Required'),
        floorDimension: Yup.object().shape({
          length: Yup.number().required('Required'),
          breath: Yup.number().required('Required'),
          height: Yup.number().required('Required'),
        }),
      }),
    ),
    racks: Yup.array(
      Yup.object().shape({
        rackInfo: Yup.object().shape({
          ...(rackracksSlottedYes === true
            ? {
                quantity: Yup.number().required('quantity is required.'),
                length: Yup.number().required('length is required.'),
                breath: Yup.number().required('breath is required.'),
                height: Yup.number().required('height is required.'),
              }
            : {}),
          ...(rackracksSlottYes === true
            ? {
                quantity: Yup.number().required('quantity is required.'),
                length: Yup.number().required('length is required.'),
                breath: Yup.number().required('breath is required.'),
                height: Yup.number().required('height is required.'),
              }
            : {}),
        }),
      }),
    ),
  });

  const handleAddFloor = () => {
    var allItems = { ...storeForm };
    allItems.floors.push({
      floorType: '',
      floorDimension: {
        dimension: 'SQFT',
        length: Number(),
        breath: Number(),
        height: Number(),
      },
      availableSpace: {
        length: Number(),
        breath: Number(),
        height: Number(),
      },
      unit: 'FEET',
    });
    setStoreForm(allItems);
    // setStoreForm({
    //   ...storeForm,
    //   floors: [
    //     ...storeForm.floors,
    //     {
    //       floorType: '',
    //       floorDimension: {
    //         dimension: 'SQFT',
    //         length: Number(),
    //         breath: Number(),
    //         height: Number(),
    //       },
    //       availableSpace: {
    //         dimension: 'SQFT',
    //         length: Number(),
    //         breath: Number(),
    //         height: Number(),
    //       },
    //       unit: 'FEET',
    //     },
    //   ],
    // });
  };

  const handleChangheFloorKeys = (event, name) => {
    setStoreForm({ ...storeForm, [name]: event });
  };
  const handleChangeFloorType = (event, name, index) => {
    const updatedFloors = [...storeForm.floors];
    updatedFloors[index].floorType = event.value;
    setStoreForm({ ...storeForm, floors: updatedFloors });
  };

  const handleChangeFloor = (value, name, index) => {
    const updatedFloors = [...storeForm.floors];
    updatedFloors[index].floorDimension[name] = value;
    setStoreForm({ ...storeForm, floors: updatedFloors });
  };

  const handleChangeRacks = (key, value, index) => {
    const updatedFloors = [...storeForm.racks];
    updatedFloors[index].rackInfo[key] = value;
    setStoreForm({ ...storeForm, racks: updatedFloors });
  };

  const handleDeleteFloor = (id) => {
    setStoreForm((prevState) => ({
      ...prevState,
      floors: prevState.floors.filter((_, i) => i !== id),
    }));
  };

  const handleChangeSpace = () => {};
  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse/${warehouseId}/it-infra`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);
  useEffect(() => {
    if (
      warehouseId !== undefined &&
      slug !== null &&
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRes?.warehouseStorageSpaces !== undefined &&
      data?.singleFormData?.warehouseRes?.warehouseStorageSpaces !== null
    ) {
      setStoreForm({ ...data?.singleFormData?.warehouseRes?.warehouseStorageSpaces[0] });
    }
    if (
      warehouseId !== undefined &&
      slug !== null &&
      data?.singleFormData?.remarks === 1 &&
      data?.singleFormData?.warehouseRes?.warehouseStorageSpacesRemarks !== undefined &&
      data?.singleFormData?.warehouseRes?.warehouseStorageSpacesRemarks !== null
    ) {
      setStoreForm(data?.singleFormData?.warehouseRes?.warehouseStorageSpacesRemarks);
    }
  }, [data]);

  // const handleYes = (e) => {
  //   setStoreForm({ ...storeForm, [e.target.name]: e.target.value === 'true' });
  // };

  return (
    <>
      {data?.isLoading ? (
        <CardLoader />
      ) : (
        <div className='row align-items-center pb-3 mx-0'>
          <div className='col-12'>
            <Formik
              enableReinitialize={true}
              initialValues={storeForm}
              validationSchema={storeSchema}
              onSubmit={(fields) => {
                let payloadData = {
                  warehouseStorageSpaces: fields,
                };
                payloadData['warehouseId'] = warehouseId;
                payloadData['slug'] = slug;
                dispatch(consiousUpdateStorageSpaceNew(payloadData));
              }}
              render={(values, errors, setFieldValue) => {
                // console.log(values, 'values Fiele');
                const fieldClass = (fieldName, isYes = true) => {
                  return (
                    'form-control form-control-sm bg-white px-4 w-5' +
                    (isYes
                      ? values.errors[fieldName] && values.touched[fieldName]
                        ? ' is-invalid'
                        : ''
                      : '')
                  );
                };

                const floorMultiRowValidation = (id, fieldname) => {
                  if (values?.errors !== undefined && values?.errors[`floors`] !== undefined) {
                    if (values?.errors[`floors`][id]?.floorDimension !== undefined) {
                      if (values?.errors[`floors`][id]?.floorDimension[fieldname] !== undefined) {
                        return 'form-control form-control-sm bg-white px-4 w-5 is-invalid';
                      } else {
                        return 'form-control form-control-sm bg-white px-4 w-5';
                      }
                    } else {
                      return 'form-control form-control-sm bg-white px-4 w-5';
                    }
                  } else {
                    return 'form-control form-control-sm bg-white px-4 w-5';
                  }
                };

                const floorTypeValidation = (id, selected) => {
                  if (values?.errors?.floors?.[id]?.floorType !== undefined) {
                    return ' w-60 mr-5 float-left is-invalid  border-radius';
                  } else {
                    return ' w-60 mr-5 float-left  border-radius';
                  }
                };

                const rackMultiRowValidation = (id, fieldname, status) => {
                  if (values?.errors !== undefined && values?.errors[`racks`] !== undefined) {
                    if (
                      values?.errors[`racks`][id]?.rackInfo[fieldname] !== undefined &&
                      status === true
                    ) {
                      return 'form-control bg-white px-4 is-invalid';
                    } else {
                      return 'form-control bg-white px-4';
                    }
                  } else {
                    return 'form-control bg-white px-4';
                  }
                };
                const totalLength = storeForm.floors.reduce(function (acc, current) {
                  return acc + current?.floorDimension.length;
                }, 0);

                const totalBreath = storeForm.floors.reduce(function (acc, current) {
                  return acc + current?.floorDimension.breath;
                }, 0);

                return (
                  <>
                    <Form>
                      <div className='row bg-white rounded mx-0 col-xxxl-11 justify-content-center'>
                        <div className='form-group form-inline w-90 mb-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-1 mr-3'>
                            Storage Type:
                          </label>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name='storageType'
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='floor-area'
                              value='floorarea'
                              hidden=''
                              disabled={viewMood}
                            />
                            <label
                              className='common-radio-deep-blue-label pl-4'
                              htmlFor='floor-area'
                            >
                              Floor Area
                            </label>
                          </div>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name='storageType'
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='pallet-position'
                              value='palletposition'
                              hidden=''
                              disabled={viewMood}
                            />
                            <label
                              className='common-radio-deep-blue-label pl-4'
                              htmlFor='pallet-position'
                            >
                              Pallet Position
                            </label>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between h3 w-90'>
                          Floor Plan{' '}
                          <button
                            type='button'
                            class='btn btn-secondary'
                            onClick={handleAddFloor}
                            disabled={viewMood}
                          >
                            Add Floor
                          </button>
                        </div>
                        {storeForm?.floors.map((field, index) => {
                          return (
                            <div className='main w-90 border my-3' key={index}>
                              <div>
                                {index !== 0 ? (
                                  <span
                                    onClick={() => handleDeleteFloor(index)}
                                    className='ml-4 cursors float-right my-2'
                                  >
                                    <DeleteIcon />
                                  </span>
                                ) : (
                                  <></>
                                )}
                                <label htmlFor='' className='px-1 fontSize'>
                                  Floor *
                                </label>

                                {/* <Select
                                  value={floorTypesOptions[index]}
                                  className={floorTypeValidation(`${index}`)}
                                  options={floorTypesOptions}
                                  name={`floors[${index}].floorType`}
                                  onChange={(event) => {
                                    handleChangeFloorType(event, 'floorType', index);
                                    values.setFieldValue(`floors[${index}].floorType`, event.value);
                                  }}
                                  isDisabled={viewMood}
                                /> */}

                                <Select
                                  value={{
                                    value:
                                      values.values.floors[index]?.floorType === ''
                                        ? ''
                                        : values.values.floors[index]?.floorType,
                                    label:
                                      values.values.floors[index]?.floorType === '' ||
                                      values.values.floors[index]?.floorType === undefined
                                        ? 'Select Floor'
                                        : values.values.floors[index]?.floorType,
                                  }}
                                  className={floorTypeValidation(`${index}`)}
                                  options={floorTypesOptions}
                                  name={`floors[${index}].floorType`}
                                  onChange={(event) => {
                                    handleChangeFloorType(event, 'floorType', index);
                                    values.setFieldValue(`floors[${index}].floorType`, event.value);
                                  }}
                                />

                                <ErrorMessage
                                  name={`floors[${index}].floorType`}
                                  component='div'
                                  className='invalid-feedback float-left'
                                />
                              </div>
                              <div className='d-inline-flex'>
                                <span className='py-3 pr-4'>
                                  <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                    Length *
                                  </label>
                                  <Field
                                    name={`floors[${index}].floorDimension.length`}
                                    value={storeForm.floors[index].floorDimension.length}
                                    type='number'
                                    placeholder='Length'
                                    className={floorMultiRowValidation(`${index}`, 'length')}
                                    onChange={(event) => {
                                      // console.log(event.target);
                                      let storeFormSW = { ...storeForm };
                                      storeFormSW.floors[index].floorDimension.length =
                                        event.target.value !== ''
                                          ? parseInt(event.target.value)
                                          : '';
                                      setStoreForm(storeFormSW);
                                      values.setFieldValue(
                                        `floors[${index}].floorDimension.length`,
                                        event.target.value !== ''
                                          ? parseInt(event.target.value)
                                          : '',
                                      );
                                    }}
                                    disabled={viewMood}
                                  />
                                  <ErrorMessage
                                    name={`floors[${index}].floorDimension.length`}
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </span>
                                <span className='py-3 px-4'>
                                  <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                    Breadth *
                                  </label>
                                  <Field
                                    type='number'
                                    name={`floors[${index}].floorDimension.breath`}
                                    value={storeForm.floors[index].floorDimension.breath}
                                    onChange={(event) => {
                                      let storeFormSW = { ...storeForm };
                                      storeFormSW.floors[index].floorDimension.breath =
                                        event.target.value !== ''
                                          ? parseInt(event.target.value)
                                          : '';
                                      setStoreForm(storeFormSW);
                                      values.setFieldValue(
                                        `floors[${index}].floorDimension.breath`,
                                        event.target.value !== ''
                                          ? parseInt(event.target.value)
                                          : '',
                                      );
                                    }}
                                    placeholder='Breadth'
                                    className={floorMultiRowValidation(`${index}`, 'breath')}
                                    disabled={viewMood}
                                  />
                                  <ErrorMessage
                                    name={`floors[${index}].floorDimension.breath`}
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </span>
                                <span className='py-3 px-4'>
                                  <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                    Height *
                                  </label>
                                  <Field
                                    name={`floors[${index}].floorDimension.height`}
                                    value={storeForm.floors[index].floorDimension.height}
                                    onChange={(event) => {
                                      let storeFormSW = { ...storeForm };
                                      storeFormSW.floors[index].floorDimension.height =
                                        event.target.value !== ''
                                          ? parseInt(event.target.value)
                                          : '';
                                      setStoreForm(storeFormSW);
                                      values.setFieldValue(
                                        `floors[${index}].floorDimension.height`,
                                        event.target.value !== ''
                                          ? parseInt(event.target.value)
                                          : '',
                                      );
                                    }}
                                    type='number'
                                    placeholder='Height'
                                    className={floorMultiRowValidation(`${index}`, 'height')}
                                    disabled={viewMood}
                                  />
                                  <ErrorMessage
                                    name={`floors[${index}].floorDimension.height`}
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </span>
                              </div>
                              <div>
                                <label htmlFor='' className='px-1 fontSize'>
                                  Available Space
                                </label>
                              </div>
                              <div className='d-inline-flex'>
                                <span className='pr-4'>
                                  <Field
                                    name={`floors[${index}].availableSpace.length`}
                                    value={storeForm.floors[index].availableSpace.length}
                                    onChange={(event) => {
                                      let storeFormSW = { ...storeForm };
                                      storeFormSW.floors[index].availableSpace.length = parseInt(
                                        event.target.value,
                                      );
                                      setStoreForm(storeFormSW);
                                      values.setFieldValue(
                                        `floors[${index}].availableSpace.length`,
                                        event.target.value,
                                      );
                                    }}
                                    type='number'
                                    placeholder='Length'
                                    className={
                                      'form-control form-control-sm bg-white px-4 w-5 mb-3'
                                    }
                                    // onChange={(event) => handleChangeSpace(event, index)}
                                    disabled={viewMood}
                                  />
                                </span>
                                <span className='px-4'>
                                  <Field
                                    name={`floors[${index}].availableSpace.breath`}
                                    value={storeForm.floors[index].availableSpace.breath}
                                    onChange={(event) => {
                                      let storeFormSW = { ...storeForm };
                                      storeFormSW.floors[index].availableSpace.breath = parseInt(
                                        event.target.value,
                                      );
                                      setStoreForm(storeFormSW);
                                      values.setFieldValue(
                                        `floors[${index}].availableSpace.breath`,
                                        event.target.value,
                                      );
                                    }}
                                    type='number'
                                    placeholder='Breadth'
                                    className={'form-control form-control-sm bg-white px-4 w-5'}
                                    // onChange={(event) => handleChangeSpace(event, index)}
                                    disabled={viewMood}
                                  />
                                </span>
                                <span className='px-4'>
                                  <Field
                                    name={`floors[${index}].availableSpace.height`}
                                    value={storeForm.floors[index].availableSpace.height}
                                    onChange={(event) => {
                                      let storeFormSW = { ...storeForm };
                                      storeFormSW.floors[index].availableSpace.height = parseInt(
                                        event.target.value,
                                      );
                                      setStoreForm(storeFormSW);
                                      values.setFieldValue(
                                        `floors[${index}].availableSpace.height`,
                                        event.target.value,
                                      );
                                    }}
                                    type='number'
                                    placeholder='Height'
                                    className={'form-control form-control-sm bg-white px-4 w-5'}
                                    // onChange={(event) => handleChangeSpace(event, index)}
                                    disabled={viewMood}
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                        <div className='mb-3 w-90'>
                          <label className='mb-2'>Total Area(sqft):</label>
                          <div className='d-flex'>
                            <Field
                              className='form-control bg-white px-4 mr-3 col-2'
                              name={`totalAvailableSpace`}
                              value={totalLength * totalBreath}
                              disabled
                            />

                            {/* <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 col-2'
                              value={0}
                              disabled
                            /> */}
                          </div>
                          <div></div>
                        </div>
                        <div className='my-2 w-90'>
                          <label className='mb-2'>Pallets on Floor</label>
                          <Field
                            className='form-control bg-white px-4 w-50'
                            name={`palletsOnFloor`}
                            // onChange={(event) =>
                            //   handleChangheFloorKeys(event.target.value, `palletsOnFloor`)
                            // }
                            disabled={viewMood}
                          />
                        </div>
                        <div className='my-3 border w-90'>
                          <label className='mb-2'>Racks(Slotted Angle) No of Bins: </label>
                          <div className='d-flex'>
                            <div className='form-check common-radio-deep-blue'>
                              <Field
                                name={`racksSlottedYes`}
                                // onChange={(e) => handleYes(e)}
                                onChange={(e) => {
                                  values.setFieldValue('racksSlottedYes', true);
                                  setRackracksSlottedYes(true);
                                }}
                                // onChange={values.handleChange}
                                // defaultChecked={values.racksSlottedYes === true}
                                checked={values.values.racksSlottedYes}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksSlotted'
                                value={true}
                                hidden=''
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksSlotted'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                name={`racksSlottedYes`}
                                // onChange={(e) => handleYes(e)}
                                onChange={(e) => {
                                  values.setFieldValue('racksSlottedYes', false);
                                  setRackracksSlottedYes(false);
                                }}
                                checked={!values.values.racksSlottedYes}
                                // onChange={values.handleChange}
                                // defaultChecked={values.racksSlottedYes === false}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksSlotted1'
                                value={false}
                                hidden=''
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksSlotted1'
                              >
                                No
                              </label>
                            </div>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Quantity *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.quantity`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'quantity',
                                  values.values.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.quantity`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Length *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.length`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'length',
                                  values.values.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.length`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Breath *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.breath`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'breath',
                                  values.values.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.breath`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Height *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.height`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'height',
                                  values.values.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.height`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                          </div>
                        </div>
                        <div className='my-3 border w-90'>
                          <label className='mb-2'>Racks(Shelve Racks) No of Shelve:</label>
                          <div className='d-flex'>
                            <div className='form-check common-radio-deep-blue'>
                              <Field
                                name={`racksShelveYes`}
                                checked={values.values.racksShelveYes}
                                // onChange={(e) => handleYes(e)}
                                onChange={(e) => {
                                  values.setFieldValue('racksShelveYes', true);
                                  setRackracksSlottYes(true);
                                }}
                                value={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksShelveYes'
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksShelveYes'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                name={`racksShelveYes`}
                                // onChange={(e) => handleYes(e)}
                                onChange={(e) => {
                                  values.setFieldValue('racksShelveYes', false);
                                  setRackracksSlottYes(false);
                                }}
                                checked={!values.values.racksShelveYes}
                                value={false}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksShelveYes1'
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksShelveYes1'
                              >
                                No
                              </label>
                            </div>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Quantity *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.quantity`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'quantity',
                                  values.values.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksShelveYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.quantity`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Length *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.length`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'length',
                                  values.values.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksShelveYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.length`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Breath *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.breath`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'breath',
                                  values.values.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksShelveYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.breath`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Height *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.height`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'height',
                                  values.values.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? values.values.racksShelveYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.height`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                          </div>
                        </div>
                        <div className='my-2 w-90'>
                          <label className='mb-2'>No of Shifts</label>
                          <Field
                            // className='form-control bg-white px-4 w-50'
                            type='number'
                            name={`noOfShift`}
                            placeholder='Enter No Of Shifts'
                            onChange={(event) => {
                              let floors = { ...storeForm };
                              floors.noOfShift = parseInt(event.target.value);
                              setStoreForm(floors);
                              values.setFieldValue('noOfShift', event.target.value);
                            }}
                            className={fieldClass('noOfShift')}
                            disabled={viewMood}
                          />
                          <ErrorMessage
                            name={`noOfShift`}
                            component='div'
                            className='invalid-feedback'
                          />
                        </div>

                        {!viewMood && (
                          <div className={'row justify-content-end'}>
                            <div className='col-auto'>
                              <button
                                type='submit'
                                className='btn btn-deep-blue add-className remove-className my-2'
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  </>
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateStorageSpaceForm;
