import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../layout/Layout';
import Icon from './icon.jpg';
import { BsKey } from 'react-icons/bs';
import { BsPersonRaisedHand } from 'react-icons/bs';
import ImageSlider from '../../wrapper/customer/WH/ImageSlider';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { BsGrid3X3Gap } from 'react-icons/bs';
import { MdOutlineElectricBolt } from 'react-icons/md';
import { MdOutlineWorkOutline } from 'react-icons/md';
import { FaFireExtinguisher } from 'react-icons/fa6';
import { PiWarehouse } from 'react-icons/pi';
import { MdSecurity } from 'react-icons/md';
import { TbTruckLoading } from 'react-icons/tb';
import { addToCart } from '../../store/actions/customer/cartAction';
import { initialRfqByIdAndType } from '../../store/actions/customer/rfqAction';
import { AsyncImage } from 'loadable-image';
// import Spinner from 'react-bootstrap/Spinner';
// import Rating from '@mui/material/Rating';
// import Typography from '@mui/material/Typography';
// import loadjs from 'loadjs';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// import Data from './data';
import ImageGallery from 'react-image-gallery';
import * as Yup from 'yup';
import '../../style/css/plugins.css';
import '../../style/css/custom.css';
import '../../style/common.css';
// import './warehouse.css';
import {
  fetchWarehouseById,
  // fetchWarehouseByIdAndTypeNew,
  // warehouseDetailPage,
  getWarehouseByCity,
} from '../../store/actions/warehouseAction';
import Maps from './Maps1';
import axios from '../../api/axios-auth';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';

// import { onlyNumberAllow } from '../../components/validation';
// import Button from 'react-bootstrap/Button';
// import { favoriteAdd, favoriteRemove } from '../../store/actions/customer/favoriteAction';
import { authUser, isAuth } from '../../util/auth';
import PriceGraph from './PriceGraph';

// import InDoorImages from "./InDoorImages";

const WarehouseDetails = () => {
  // let share_url = window.location.href;
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.authenticated);
  const [images, setImages] = useState([]);
  const [popup, setPopup] = useState(false);
  const [images1, setImages1] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [thankYou, setThankYou] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    typeError: false,
    errMsg: '',
  });
  const user = authUser();
  const history = useHistory();
  // const myRefname2 = useRef(null);
  const myRefname3 = useRef(null);
  let gettablength = document.getElementsByClassName('indoor-outdoor-tab-open');

  for (let i = 0; i < gettablength.length; i++) {
    gettablength[i].addEventListener('click', function () {
      for (let i = 0; i < gettablength.length; i++) {
        let gettarget = gettablength[i]
          .getAttribute('data-target')
          .slice(1)
          .replace('modal', 'tab');
        let datatargettab = gettablength[i].getAttribute('data-target-tab').slice(1);
        document
          .getElementById(gettarget)
          .getElementsByClassName('nav-link')
          [i].classList.remove('active');
        document.getElementById(datatargettab).classList.remove('active');
        document.getElementById(datatargettab).classList.remove('show');
      }
      let datatargettabactive = gettablength[i].getAttribute('data-target-tab').slice(1);
      document.getElementById(datatargettabactive + '-tab').classList.add('active');
      document.getElementById(datatargettabactive).classList.add('active');
      document.getElementById(datatargettabactive).classList.add('show');
    });
  }
  const data = useSelector((state) => state.WAREHOUSELIST);
  const [ware, setware] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);

  const [selectedOption, setselectedOption] = useState(null);
  const [waresheet, setwaresheet] = useState({
    note: '',
    imgUrl: '',
  });
  let formValidation = Yup.object().shape({
    waresheetName: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/^[A-Za-z ]*$/, 'Please enter valid Name')
      .required('Warehouse Name is required'),

    description: Yup.string()
      .min(3, 'Too Short!')
      .matches(/^[\w .,!?()]+$/, 'Please enter valid Decription')
      .required('Description is required'),
  });

  const items = useSelector((state) => state.WAREHOUSEINFO);
  const [email, setemail] = useState({
    storageType: '',
    NoOfPallets: '',
    AreaRequired: '',
    code: '',
    mobile: '',
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      data: `
    <h3 style=>Storage Type: ${email.storageType}</h3><br/>
    <h3 style=>No Of Pallets: ${email.NoOfPallets}</h3><br/>
    <h3 style=>Mobile Number: ${email.mobile}</h3><br/>
    <h3 style=>Area Required: ${email.AreaRequired}</h3><br/>
    <h4 style=>Cheers!</h4>
    <h4 style=>Warehousity Team</h4>
    `,
    };
    axios
      .post('/api/v1/user/booknow', obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.statusCode === 200) {
          setModalIsOpen(true);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // const reportwarehouse = () => {
  //   if (authed) {
  //     setModalIsOpen(true);
  //   } else {
  //     addToast('Please Login', { appearance: 'error', autoDismiss: true });
  //   }
  // };

  const [loadwaresheets, setloadwaresheets] = useState([]);
  const loadwaresheet = () => {
    axios
      .get('/api/v1/waresheet?page=1&limit=1000')
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.statusCode === 200) {
          setloadwaresheets(res.data);
          // console.log('res--=======>', res.data);
          let lb = res.data.map((item) => {
            return {
              label: item.waresheetName,
              value: res.data.id,
            };
          });
          setselectedOption(lb);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const [noteError, setNoteError] = useState(null);
  const createwaresheet = (fields) => {
    let obj = {
      waresheetName: fields.waresheetName,
      description: fields.description,
      makeItDefault: fields.makeItDefault,
    };
    axios
      .post('/api/v1/waresheet', obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.statusCode === 200) {
          setModalIsOpen2(true);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const createnewwaresheet = (fields) => {
    let obj = {
      waresheetName: fields,
      description: '',
      makeItDefault: false,
    };
    axios
      .post('/api/v1/waresheet', obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.statusCode === 200) {
          setware('');
          setModalIsOpen2(true);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  // const data = useSelector((state) => state);
  const { addToast } = useToasts();
  const { warehouseId } = useParams();

  const handleChange5 = (name) => (event) => {
    setErrorMessage({ typeError: false, errMsg: '' });
    if (name === 'NoOfPallets') {
      setemail({
        ...email,
        AreaRequired: parseFloat(event.target.value * 32).toFixed(2),
        NoOfPallets: parseFloat(event.target.value),
      });
    } else if (name === 'AreaRequired') {
      setemail({
        ...email,
        NoOfPallets: parseFloat(event.target.value / 32).toFixed(2),
        AreaRequired: event.target.value,
      });
    } else {
      setemail({ ...email, error: false, [name]: event.target.value });
    }

    if (name === 'NoOfPallets' || name === 'AreaRequired') {
      if (event.target.value.length === 0) {
        setemail({ ...email, NoOfPallets: '', AreaRequired: '' });
      }
    }
  };

  const [show, setShow] = useState(false);
  const [mobilemodal, setmobilemodal] = useState(false);

  const [checkPrice, setCheckPrice] = useState(false);
  const [saveWaresheet, setSaveWaresheet] = useState(false);

  const handleClose = () => setShow(false);
  // const [open, setopen] = useState(false);
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const addCart = () => {
    if (Data && warehouseId) {
      dispatch(
        addToCart(
          {
            type: 'warehouse',
            warehouse: parseInt(warehouseId),
            NoOfPallets: 100,
            AreaRequired: 200,
          },
          addToast,
        ),
      );
    } else {
      addToast('Please Login', { appearance: 'error', autoDismiss: true });
    }
  };
  useEffect(() => {
    dispatch(fetchWarehouseById(warehouseId));
  }, [dispatch, warehouseId]);

  useEffect(() => {
    // if (items.warehouseDetailPage === null) {
    //   // setTimeout(() => {
    //   //   history.replace(`/`);
    //   // }, 500)
    // }
    // console.log(items.warehouseDetailPage, 'warehouse id if not approved show emtry');
  }, [items]);
  const [rfqLoad, setRfqLoad] = useState(false);
  const createAndRedirectOnRfq = () => {
    setRfqLoad(true);

    if (warehouseId && email.AreaRequired !== '') {
      let city =
        items &&
        items?.warehouseDetailPage &&
        items?.warehouseDetailPage?.warehouseContactDetailInfo &&
        items?.warehouseDetailPage?.warehouseContactDetailInfo?.address &&
        items?.warehouseDetailPage?.warehouseContactDetailInfo?.address?.state;

      let area =
        items &&
        items?.warehouseDetailPage &&
        items?.warehouseDetailPage?.warehouseContactDetailInfo &&
        items?.warehouseDetailPage?.warehouseContactDetailInfo?.address &&
        items?.warehouseDetailPage?.warehouseContactDetailInfo?.address?.city;

      let data = {
        warehouseSpaceRequired: parseInt(email.AreaRequired),
        warehouses: [parseInt(warehouseId)],
        location: {
          city: {
            name: city,
          },
          area: {
            name: area,
          },
        },
      };
      dispatch(getWarehouseByCity(area));
      axios
        .post(`/api/v1/customerrfq`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(initialRfqByIdAndType(res.data.id, 'customerRfq'));
            setCheckPrice(false);
            history.replace(`/createnewrfq?rfqid=${res.data.id}&hide=${true}`);
          }
        })
        .catch((error) => {})
        .then(() => {
          setRfqLoad(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {popup && <ImageSlider setPopup={setPopup} popup={popup} />}

      <Layout popup={popup}>
        <div className={`container mt-5 ${popup ? 'filter' : ''}`}>
          <div className='row align-items-center justify-content-start pt-4'>
            <div className='col-6 pt-3 px-1'>
              <nav aria-label='text-gray'>
                <ol className='d-flex pl-0 mb-0'>
                  <li className='breadcrumb-item  text-dark'>
                    <Link to={'/'} className='text-dark'>
                      Home
                    </Link>
                  </li>
                  <li
                    className='breadcrumb-item active cursorPointer  text-dark'
                    aria-current='page'
                  >
                    <Link to={'/warehouse?page=1'} className='text-dark'>
                      Warehouse
                    </Link>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    warehouse {items?.warehouseDetailPage?.id}
                  </li>
                </ol>
              </nav>
            </div>
            <div className='col-6 pt-3'>
              <span className='text-right'>
                <div className='row'>
                  <div className='col-md-12'>{/* I am share icon section */}</div>
                </div>
              </span>
            </div>
          </div>

          <div className='row'>
            <div className='row my-2'>
              <div className='col-12 d-flex px-1'>
                <span className='mt-2 text-dark'>{/*items?.warehouseDetailPage?.id */}</span>
                <span className='text-capitalize mt-2 text-dark h4 mx-1'>
                  {items?.warehouseDetailPage?.warehouseContacts !== undefined
                    ? items?.warehouseDetailPage?.warehouseContacts[0]?.warehouseName
                    : ''}
                </span>
              </div>
            </div>
            <div className='col-6 px-2'>
              <AsyncImage
                src={
                  items?.warehouseDetailPage?.remarks === 1
                    ? items?.warehouseDetailPage?.warehouseImages !== undefined
                      ? items?.warehouseDetailPage?.warehouseImages?.whsCoverImage?.coverImg?.value
                      : ''
                    : items?.warehouseDetailPage?.warehouseImages !== undefined
                    ? items?.warehouseDetailPage?.warehouseImages[0]?.cover
                    : ''
                }
                // style={{ width: 150, height: 150, borderRadius: 3 }}
                style={{ height: 300, width: 550 }}
                alt='cover'
                // className='img-fluid cover-image h-600 pb-2'
              />
            </div>

            <div
              className='col-6 col-sm-6 col-md-6 col-xl-6 pl-0 pr-2 d-flex'
              style={{ flexWrap: 'wrap' }}
            >
              <AsyncImage
                src={
                  items?.warehouseDetailPage?.remarks === 1
                    ? items?.warehouseDetailPage?.warehouseImages !== undefined
                      ? items?.warehouseDetailPage?.warehouseImages?.whsIndoors?.indoor[0]?.value[0]
                      : ''
                    : items?.warehouseDetailPage?.warehouseImages !== undefined
                    ? items?.warehouseDetailPage?.warehouseImages[0]?.photo?.indoor[0]
                    : ''
                }
                style={{ height: 147, width: 270, marginRight: 4, marginBottom: 4 }}
                alt='indoor'
                // className='img-fluid w-50 multiple-images pr-2 pb-2'
              />
              <AsyncImage
                src={
                  items?.warehouseDetailPage?.remarks === 1
                    ? items?.warehouseDetailPage?.warehouseImages !== undefined
                      ? items?.warehouseDetailPage?.warehouseImages?.whsOutdoors?.outdoor[0]
                          ?.value[0]
                      : ''
                    : items?.warehouseDetailPage?.warehouseImages !== undefined
                    ? items?.warehouseDetailPage?.warehouseImages[0]?.photo?.outdoor[0]
                    : ''
                }
                alt='outdoor'
                style={{ height: 147, width: 270, marginLeft: 4, marginBottom: 4 }}
                // loader={<div style={{ background: '#888' }} />}
                // error={<div style={{ background: '#222' }} />}
                // className='img-fluid w-50 multiple-images pr-2 pb-2'
              />
              <AsyncImage
                src={
                  items?.warehouseDetailPage?.remarks === 1
                    ? items?.warehouseDetailPage?.warehouseImages !== undefined
                      ? items?.warehouseDetailPage?.warehouseImages?.whsIndoors?.indoor[0]?.value[1]
                      : ''
                    : items?.warehouseDetailPage?.warehouseImages !== undefined
                    ? items?.warehouseDetailPage?.warehouseImages[0]?.photo?.indoor[1]
                    : ''
                }
                style={{ height: 147, width: 270, marginRight: 4, marginTop: 4 }}
                alt='indoor'
                // className='img-fluid w-50 multiple-images pr-2 pb-2'
              />
              <AsyncImage
                src={
                  items?.warehouseDetailPage?.remarks === 1
                    ? items?.warehouseDetailPage?.warehouseImages !== undefined
                      ? items?.warehouseDetailPage?.warehouseImages?.whsOutdoors?.outdoor[0]
                          ?.value[1]
                      : ''
                    : items?.warehouseDetailPage?.warehouseImages !== undefined
                    ? items?.warehouseDetailPage?.warehouseImages[0]?.photo?.outdoor[1]
                    : ''
                }
                style={{ height: 147, width: 270, marginLeft: 4, marginTop: 4 }}
                alt='outdoor'
                // className='img-fluid w-50 multiple-images pr-2 pb-2'
              />
              <button
                type='button'
                class='btn btn-light position-absolute right-0 bottom-0 mr-1 mb-2 border border-dark'
                style={{ right: '25px' }}
                onClick={() => {
                  setPopup(true);
                  document.body.classList.add('no-scroll');
                }}
              >
                <BsGrid3X3Gap /> See More Photos
              </button>
            </div>
          </div>
        </div>

        <div className='col-auto col-sm-12 col-md-10 d-flex justify-content-end custom-margin pr-4 py-4'>
          <button
            onClick={addCart}
            // disabled={data.CARTINFO.isPending}
            type='button'
            className='btn btn-block btn-deep-primary my-1 py-2 w-15'
          >
            Add to Cart
          </button>
        </div>

        {/* <div className='col-auto col-sm-12 col-md-12 d-flex justify-content-center mx-5 my-3'>
                    <div className='col-auto col-sm-12 col-md-10 h5'>
                     <span>
                      Warehousing is a process of storing goods in a warehouse for the purpose of distribution,
                      sale, or manufacturing.Warehouses are used for storing goods for an extended period of 
                      time and are typically equipped with storage areas,loading docks, conveyors, and other 
                      material-handling equipment. Warehousing also involves the movement and storage of 
                      inventory inand out of the warehouse, as well as the tracking of goods and inventory.
                     </span>
                    </div>
                    </div> */}

        <div
          className='col-12 col-sm-12 col-md-12 warehouse-about-home position-relative my-1'
          style={{ marginLeft: '12rem' }}
        >
          {/* <div className='warehouse-about-home '>
          <span>About Warehouse</span>
        </div>
        <p className='text-justify text-dark col-md-9 px-0'>
          This warehouse in{' '}
          {items !== undefined &&
          items?.warehouseDetailPage !== undefined &&
          items?.warehouseDetailPage?.warehouseContacts !== undefined &&
          items?.warehouseDetailPage?.warehouseContacts[0]?.address1 &&
          items?.warehouseDetailPage?.warehouseContacts[0]?.state
            ? items?.warehouseDetailPage?.warehouseContacts[0]?.state +
              ' is one of the finest in the State.' +
              'Warehouse is strategically located in the' +
              items?.warehouseDetailPage?.warehouseContacts[0]?.address1
            : null}{' '}
          {items !== undefined &&
            items?.warehouseDetailPage !== undefined &&
            items?.warehouseDetailPage?.warehouseContacts !== undefined &&
            items?.warehouseDetailPage?.warehouseContacts[0]?.address &&
            items?.warehouseDetailPage?.warehouseContacts[0]?.district}
          . The total area of the warehouse is{' '}
          {items !== undefined &&
          items?.warehouseDetailPage !== undefined &&
          items?.warehouseDetailPage?.warehouseStorageSpaces !== undefined &&
          ' ' + items?.warehouseDetailPage?.warehouseStorageSpaces[0]?.totalAvailableSpace
            ? items?.warehouseDetailPage?.warehouseStorageSpaces[0]?.totalAvailableSpace
            : null}
          and currently available space in this warehouse is{' '}
          {items !== undefined &&
            items?.warehouseDetailPage !== undefined &&
            items?.warehouseDetailPage?.warehouseStorageSpaces !== undefined &&
            ' ' +
              items?.warehouseDetailPage?.warehouseStorageSpaces[0].totalAvailableSpace +
              ' ' +
              'Sqft' +
              ' '}{' '}
          . This is a warehouse with a built. The warehouse operates in
          {items !== undefined &&
            items?.warehouseDetailPage !== undefined &&
            items?.warehouseDetailPage?.warehouseStorageSpaces !== undefined &&
            ' ' + items?.warehouseDetailPage?.warehouseStorageSpaces[0]?.noOfShift + ' '}{' '}
          shifts from Monday to Saturday but is very much flexible considering the customer need.
        </p> */}
        </div>

        <section className={`pt-0 pb-0 ${popup ? 'filter' : ''}`}>
          <div className='container px-0'>
            <div class='Programs_category__fFoKi'>
              <h3 className='border-bottom col-sm-12 fontSize py-3 px-3 mb-0'>Total Area</h3>
              <div className='  d-flex px-2 py-4' style={{ gap: '4px' }}>
                {items !== undefined &&
                items?.warehouseDetailPage !== null &&
                items?.warehouseDetailPage?.warehouseStorageSpaces !== undefined
                  ? items?.warehouseDetailPage?.warehouseStorageSpaces[0]?.floors?.map(
                      (elem, index) => {
                        return (
                          <div
                            key={index}
                            className='d-flex   col-sm-3 align-items-center'
                            style={{ gap: '6px' }}
                          >
                            <img src={Icon} height={'30px'} width={'30px'} />
                            <div className=''>
                              <div className='w-100  textgray'>{elem.floorType}</div>
                              <div className='fontSizeIn'>
                                {' '}
                                {elem.floorDimension.length * elem.floorDimension.breath} sqft
                              </div>
                            </div>
                          </div>
                        );
                      },
                    )
                  : ''}
              </div>
            </div>
            <div className='Programs_category__fFoKi  mt-3'>
              <h3 className='border-bottom col-sm-12 fontSize py-3 px-3 mb-0'>Available Area</h3>
              <div className='  d-flex px-2 py-4' style={{ gap: '4px' }}>
                {items !== undefined &&
                items?.warehouseDetailPage !== null &&
                items?.warehouseDetailPage?.warehouseStorageSpaces !== undefined
                  ? items?.warehouseDetailPage?.warehouseStorageSpaces[0]?.floors?.map(
                      (elem, index) => {
                        return (
                          <div
                            key={index}
                            className='d-flex   col-sm-3 align-items-center'
                            style={{ gap: '8px' }}
                          >
                            <img src={Icon} height={'30px'} width={'30px'} />
                            <div className=''>
                              <div className='w-100  textgray'>{elem.floorType}</div>
                              <div className='h6 fontSizeIn'>
                                {elem?.availableSpace?.length * elem?.availableSpace?.breath} sqft
                              </div>
                            </div>
                          </div>
                        );
                      },
                    )
                  : ''}
              </div>
            </div>
            <div className='row'>
              <span className='home-text-heading mx-3  py-4 mt-3'>Features and Services</span>

              <div className='col-lg-12'>
                <div className='row '>
                  {/*new code*/}

                  {/*new code*/}

                  {/*new code*/}

                  {/*new code*/}
                  {/* Accessibility */}
                  <div className='col-md-4 d-flex '>
                    <div className='box-blue-light white col-12 h-100 py-3'>
                      <div
                        class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                        style={{ gap: '8px' }}
                      >
                        {' '}
                        <span style={{ fontSize: '1.5rem' }}>
                          <BsKey />
                        </span>
                        Accessibility
                      </div>
                      <ul className='px-0'>
                        <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center font-weight-normal'>
                          <div className='pl-1 col-12 d-flex justify-content-between'>
                            <span>Nearest Police Station</span>
                            <span>
                              {' '}
                              {items !== undefined &&
                                items?.warehouseDetailPage !== undefined &&
                                items?.warehouseDetailPage?.warehouseFormEighty !== undefined &&
                                items?.warehouseDetailPage?.warehouseFormEighty?.length > 0 &&
                                items?.warehouseDetailPage?.warehouseFormEighty[5]?.input}{' '}
                              {items &&
                              items?.warehouseDetailPage &&
                              items?.warehouseDetailPage?.warehouseFormEighty &&
                              items?.warehouseDetailPage?.warehouseFormEighty?.length > 0 &&
                              items?.warehouseDetailPage?.warehouseFormEighty[5]?.input
                                ? 'km'
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='d-flex col-lg-12 px-0 col-md-6 py-2 mb-0 h6 align-items-center  font-weight-normal'>
                          <div className='pl-1 col-12 d-flex justify-content-between'>
                            <span>Nearest Fire Station</span>
                            <span>
                              {items &&
                                items?.warehouseDetailPage &&
                                items?.warehouseDetailPage?.warehouseFormEighty &&
                                items?.warehouseDetailPage?.warehouseFormEighty?.length > 0 &&
                                items?.warehouseDetailPage?.warehouseFormEighty[1]?.input}{' '}
                              {items &&
                              items?.warehouseDetailPage &&
                              items?.warehouseDetailPage?.warehouseFormEighty &&
                              items?.warehouseDetailPage?.warehouseFormEighty?.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[1].input
                                ? 'km'
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center  font-weight-normal'>
                          <div className='pl-1 col-12 d-flex justify-content-between'>
                            <span>Nearest School</span>
                            <span>
                              {' '}
                              {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[6].input}{' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[6].input
                                ? 'm'
                                : 'N/A'}
                            </span>
                          </div>
                        </li>

                        <>
                          <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12 d-flex justify-content-between'>
                              <span>Nearest Metro/ Bus station</span>
                              <span>
                                {items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage.warehouseFormEighty &&
                                  items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                  items.warehouseDetailPage.warehouseFormEighty[10].input}{' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[10].input
                                  ? 'km'
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>

                          <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12  d-flex justify-content-between'>
                              <span>City center</span>
                              <span>
                                {' '}
                                {items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage.warehouseFormEighty &&
                                  items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                  items.warehouseDetailPage.warehouseFormEighty[11].input}{' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[11].input
                                  ? 'km'
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>

                          <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12 d-flex justify-content-between'>
                              <span>Labour Hub</span>
                              <span>
                                {' '}
                                {items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage.warehouseFormEighty &&
                                  items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                  items.warehouseDetailPage.warehouseFormEighty[13].input}{' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[13].input
                                  ? 'km'
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex col-lg-12 px-0 col-md-6 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12 d-flex justify-content-between'>
                              <span>Public Transport Availability</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[14].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[14].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex col-lg-12 px-0 col-md-6 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12 d-flex justify-content-between'>
                              <span>Nearest Warehousing Hub</span>
                              <span>
                                {' '}
                                {items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage.warehouseFormEighty &&
                                  items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                  items.warehouseDetailPage.warehouseFormEighty[9].input}{' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[9].input
                                  ? 'km'
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex col-lg-12 px-0 col-md-6 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12 d-flex justify-content-between'>
                              <span> Nearest Hospital</span>
                              <span>
                                {' '}
                                {items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage.warehouseFormEighty &&
                                  items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                  items.warehouseDetailPage.warehouseFormEighty[15].input}{' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[15].input
                                  ? 'km'
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12 d-flex justify-content-between'>
                              <span> WH in industrial area/WH zone </span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[4].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[4].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='d-flex col-lg-12 px-0 col-md-6 mb-0 py-2 h6 align-items-center  font-weight-normal'>
                            <div className='pl-1 col-12  d-flex justify-content-between'>
                              <span> WH in residential area</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[3].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[3].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                        </>
                      </ul>
                    </div>
                  </div>

                  {/* Affiliation */}

                  <div class='col-12 col-md-3 col-sm-6 col-xl-4 d-flex mb-2'>
                    <div class='h-100 w-100'>
                      <div className='box-blue-light white px-3 py-3'>
                        <div
                          class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                          style={{ gap: '8px' }}
                        >
                          <span style={{ fontSize: '1.5rem' }}>
                            <BsPersonRaisedHand />
                          </span>
                          Affiliation
                        </div>
                        <ul className='list-unstyled  mb-0'>
                          <li className='mb-0 d-flex h6 py-2 align-items-center font-weight-normal'>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span> Labour Union</span>
                              <span>
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[18].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[18].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex h6 py-2 align-items-center font-weight-normal'>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span> Transport Union</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[19].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[19].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='box-blue-light white  py-3 px-3 '>
                        <div
                          class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                          style={{ gap: '8px' }}
                        >
                          <span style={{ fontSize: '1.5rem' }}>
                            <MdOutlineElectricBolt />
                          </span>
                          Electricity & Electrical Fittings
                        </div>
                        <ul className='list-unstyled  mb-0'>
                          <li className='mb-0 d-flex py-2 h6 align-items-center font-weight-normal'>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span>Genset</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[35].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[35].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex py-2 h6 align-items-center font-weight-normal'>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span> Solar or Green Energy</span>
                              <span>
                                {' '}
                                {items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage?.warehouseFormEighty &&
                                  items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                                  items.warehouseDetailPage?.warehouseFormEighty?.[42]?.input}{' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[42].input
                                  ? 'km'
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='box-blue-light white px-3 py-3'>
                        <div
                          class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                          style={{ gap: '8px' }}
                        >
                          <span style={{ fontSize: '1.5rem' }}>
                            <MdOutlineWorkOutline />
                          </span>
                          Worker Facility, Health & Safety
                        </div>
                        <ul className='list-unstyled '>
                          <li className='mb-0 d-flex h6 py-2 align-items-center font-weight-normal '>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span> Pantry/Canteen</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[78].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[78].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex py-2 h6 align-items-center font-weight-normal'>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span> 24hrs drinking water</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[83].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[83].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                          <li className='mb-0 d-flex py-2 h6 align-items-center font-weight-normal'>
                            <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                              <span> First aid box</span>
                              <span>
                                {' '}
                                {items &&
                                items.warehouseDetailPage &&
                                items.warehouseDetailPage.warehouseFormEighty &&
                                items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                                items.warehouseDetailPage.warehouseFormEighty[86].input
                                  ? items.warehouseDetailPage.warehouseFormEighty[86].input
                                  : 'N/A'}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/*new code*/}

                  {/*new code*/}
                  {/* Worker Facility, Health & Safety */}

                  {/* Safety & Security */}
                  <div className='col-12 col-sm-6 col-md-4 col-xl-4'>
                    <div className='box-blue-light white px-0 py-3'>
                      <ul className='list-unstyled  row'>
                        <div
                          class='h6 mb-3 featured-heading px-0 d-flex align-items-center text-dark'
                          style={{ gap: '8px' }}
                        >
                          <span style={{ fontSize: '1.5rem' }}>
                            <MdSecurity />
                          </span>
                          Safety & Security
                        </div>
                        <li className='mb-0 d-flex col-lg-8 col-md-6 px-0 py-2 h6 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> No Of Gates</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[69].input
                                ? items.warehouseDetailPage.warehouseFormEighty[69].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex col-lg-8 col-md-6 px-0 py-2 h6 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Security Room</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[70].input
                                ? items.warehouseDetailPage.warehouseFormEighty[70].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex col-lg-8 col-md-6 px-0 py-2 h6 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> CCTV</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[76].input
                                ? items.warehouseDetailPage.warehouseFormEighty[76].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex col-lg-8 col-md-6 h6 px-0 py-2 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Gated Compound</span>
                            <span>
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty?.[4].input
                                ? items.warehouseDetailPage.warehouseFormEighty?.[4].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className='box-blue-light white px-0 py-3'>
                      <ul className='list-unstyled  row'>
                        <div
                          class='mb-3 h6 featured-heading px-0 d-flex align-items-center text-dark'
                          style={{ gap: '8px' }}
                        >
                          <span style={{ fontSize: '1.5rem' }}>
                            <PiWarehouse />
                          </span>
                          Warehouse More Details
                        </div>
                        <li className='mb-0 d-flex col-lg-12 col-md-12 px-0 py-2 h6 font-weight-normal'>
                          <div className='pl-1 col-12 pr-5 d-flex justify-content-between'>
                            <span>Warehouse Type:</span>{' '}
                            <span> {items ? items?.warehouseDetailPage?.type?.type : ''}</span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex col-lg-12 col-md-12 h6 px-0 py-2 font-weight-normal'>
                          <div className='pl-1 col-12 pr-5 d-flex justify-content-between'>
                            <span>Warehouse Category:</span>{' '}
                            <span>
                              {' '}
                              {items ? items?.warehouseDetailPage?.category?.categoryName : ''}
                            </span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex col-lg-12 col-md-12 px-0 py-2 h6 font-weight-normal'>
                          <div className='pl-1 col-12 pr-5 d-flex justify-content-between'>
                            <span>Structure Type:</span>
                            <span> Ground</span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex col-lg-12 col-md-12 px-0 py-2 h6 font-weight-normal'>
                          <div className='pl-1 col-12 pr-5 d-flex justify-content-between'>
                            <span> No Of Shifts</span>
                            <span>
                              {' '}
                              {items !== undefined &&
                              items?.warehouseDetailPage?.warehouseStorageSpaces !== undefined
                                ? items?.warehouseDetailPage?.warehouseStorageSpaces[0]?.noOfShift
                                : ''}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Warehouse Operations*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`pt-0 ${popup ? 'filter' : ''}`}>
          <div className='container'>
            <div className='row'>
              <span className='font-weight-bold home-text-heading mb-3 px-1 py-3 '>Near By</span>

              <div className='col-12'>
                <div className='row '>
                  {/* Accessibility */}
                  <div className='col-12 col-sm-6 col-md-4 col-xl-4 px-0'>
                    <div className='box-blue-light  px-2 h-100 '>
                      <div
                        class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                        style={{ gap: '8px' }}
                      >
                        {' '}
                        <span style={{ fontSize: '1.5rem' }}>
                          <BsKey />
                        </span>
                        Accessibility
                      </div>
                      <ul className='list-unstyled '>
                        <li className=' d-flex h6 mb-0 py-2 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Nearest Police Station</span>
                            <span>
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage?.warehouseFormEighty &&
                              items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage?.warehouseFormEighty[5].input
                                ? items.warehouseDetailPage.warehouseFormEighty[5].input
                                : ''}{' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[5].input
                                ? 'km'
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='d-flex h6 py-2 mb-0 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Nearest Fire Station</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage?.warehouseFormEighty &&
                              items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage?.warehouseFormEighty[1].input
                                ? items.warehouseDetailPage.warehouseFormEighty[1].input
                                : ''}{' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[1].input
                                ? 'km'
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='d-flex h6 py-2 mb-0 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Nearest School</span>
                            <span>
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage?.warehouseFormEighty &&
                              items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage?.warehouseFormEighty[6].input
                                ? items.warehouseDetailPage.warehouseFormEighty[6].input
                                : ''}{' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[6].input
                                ? 'km'
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Affiliation */}
                  <div className='col-12 col-sm-6 col-md-4 col-xl-4'>
                    <div className='box-blue-light pl-3 h-100'>
                      <div
                        class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                        style={{ gap: '8px' }}
                      >
                        <span style={{ fontSize: '1.5rem' }}>
                          <BsPersonRaisedHand />
                        </span>
                        Affiliation
                      </div>
                      <ul className='list-unstyled'>
                        <li className='mb-0 d-flex h6 py-2 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Labour Union</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage?.warehouseFormEighty &&
                              items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage?.warehouseFormEighty[18].input
                                ? items.warehouseDetailPage.warehouseFormEighty[18].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                        <li className='mb-0 d-flex h6 py-2 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Transport Union</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage?.warehouseFormEighty &&
                              items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage?.warehouseFormEighty[19].input
                                ? items.warehouseDetailPage.warehouseFormEighty[19].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Emergency Exit & Fire Prevention */}
                  <div
                    className='col-12 col-sm-6 col-md-4 col-xl-4 d-flex'
                    style={{ flexDirection: 'column' }}
                  >
                    <div className='box-blue-light pl-3  h-100'>
                      <div
                        class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                        style={{ gap: '8px' }}
                      >
                        <span style={{ fontSize: '1.5rem' }}>
                          <FaFireExtinguisher />
                        </span>
                        Emergency Exit & Fire Prevention
                      </div>
                      <ul className='list-unstyled '>
                        <li className='mb-0 d-flex h6 py-2 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Assembly Area</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage?.warehouseFormEighty &&
                              items.warehouseDetailPage?.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage?.warehouseFormEighty[21].input
                                ? items.warehouseDetailPage.warehouseFormEighty[21].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className='box-blue-light white pl-3 py-3'>
                      <div
                        class='mb-3 h6 featured-heading d-flex align-items-center text-dark'
                        style={{ gap: '8px' }}
                      >
                        <span style={{ fontSize: '1.5rem' }}>
                          <TbTruckLoading />
                        </span>
                        Warehouse Operations
                      </div>

                      <ul className='list-unstyled'>
                        <li className='mb-0 d-flex py-2 h6 align-items-center font-weight-normal'>
                          <div className='pl-1 col-sm-11 d-flex justify-content-between'>
                            <span> Operation hrs</span>
                            <span>
                              {' '}
                              {items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseFormEighty &&
                              items.warehouseDetailPage.warehouseFormEighty.length > 0 &&
                              items.warehouseDetailPage.warehouseFormEighty[72].input
                                ? items.warehouseDetailPage.warehouseFormEighty[72].input
                                : 'N/A'}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`pt-0 ${popup ? 'filter' : ''}`} style={{ marginBottom: '15rem' }}>
          <div className='container'>
            {/* About Warehouse */}

            <div className='row pt-3'>
              <div className='col-md-12'>
                <div>
                  {/* <Maps
                  // className="h-100px"
                  lat={parseFloat(28.6448)}
                  lng={parseFloat(77.216721)}
                  // id={items.warehouseDetailPage?.id}
                  // category={items.warehouseDetailPage?.category.categoryName}
                  // type={items.warehouseDetailPage?.type?.type}
                /> */}
                  {items !== undefined &&
                  items?.warehouseDetailPage !== undefined &&
                  items?.warehouseDetailPage?.warehouseContacts !== undefined ? (
                    <Maps
                      className='h-100px'
                      lat={parseFloat(
                        items &&
                          items?.warehouseDetailPage &&
                          items?.warehouseDetailPage?.warehouseContacts &&
                          items?.warehouseDetailPage?.warehouseContacts[0]?.gpsLatitude,
                      )}
                      lng={parseFloat(
                        items &&
                          items?.warehouseDetailPage &&
                          items?.warehouseDetailPage?.warehouseContacts &&
                          items?.warehouseDetailPage?.warehouseContacts[0]?.gpsLongitude,
                      )}
                    />
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>

              {/* About Warehouse */}
            </div>
          </div>
        </section>
        <Modal show={modalIsOpen1} centered size='lg'>
          <Modal.Body className='p-0'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='content col-12'>
                  <div className='pb-2 border-bottom mb-3'>
                    <button className='btn name-breadcrumb px-0 text-dark font-heading mr-3'>
                      Create New Waresheet{' '}
                    </button>
                  </div>
                  <div className='row mx-0'>
                    <div className='col-12 py-3 border'>
                      <div className='row'>
                        <Formik
                          initialValues={{
                            waresheetName: '',
                            description: '',
                            makeItDefault: false,
                          }}
                          validationSchema={formValidation}
                          onSubmit={(fields) => {
                            dispatch(createwaresheet(fields));
                          }}
                          render={({ errors, status, touched }) => (
                            <Form className='col-12 pt-2'>
                              <div className='form-group form-group-lg mb-4'>
                                <label htmlFor='exampleFormControlInput1' className='mb-3 h6'>
                                  Waresheet Name
                                </label>
                                <Field
                                  name='waresheetName'
                                  type='text'
                                  placeholder='Enter Here Waresheet Name'
                                  className={
                                    'form-control form-control-sm form-control form-control-sm-lg' +
                                    (errors.waresheetName && touched.waresheetName
                                      ? ' is-invalid'
                                      : '')
                                  }
                                />
                                <ErrorMessage
                                  name='waresheetName'
                                  component='div'
                                  className='invalid-feedback'
                                />
                              </div>
                              <div className='form-group form-group-lg mb-4'>
                                <label htmlFor='exampleFormControlTextarea1' className='mb-3 h6'>
                                  Description
                                </label>
                                <Field
                                  name='description'
                                  type='textarea'
                                  className={
                                    `form-control form-control-sm form-control form-control-sm-lg` +
                                    (errors.description && touched.description ? ' is-invalid' : '')
                                  }
                                  id='exampleFormControlTextarea1'
                                  rows='3'
                                  placeholder='Add notes, what do you like about this Warehouse?'
                                ></Field>
                                <ErrorMessage
                                  name='description'
                                  component='div'
                                  className='invalid-feedback'
                                />
                              </div>
                              <div className='col-12 px-0 py-4'>
                                <button
                                  onClick={() => setModalIsOpen1(false)}
                                  className='btn btn-outline-secondary mr-3 toggle-className my-2'
                                >
                                  Cancel
                                </button>
                                <button className='btn btn-deep-primary' type='submit'>
                                  Create
                                </button>
                              </div>
                            </Form>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modalIsOpen} centered>
          <Modal.Body className='p-0'>
            <div className='modal-content custom-modal-content'>
              <div className='modal-body custom-modal-body pt-0'>
                <div className='form-group'>
                  <label for='exampleFormControlTextarea1 mx-2'>Give us feedback</label>
                  <textarea
                    className='form-control textareaAuto'
                    id='exampleFormControlTextarea1'
                    rows='3'
                    onChange={(e) => setFeedback(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className='col-sm-6 mx-auto mb-4'>
                {!thankYou ? (
                  <div className='d-flex'>
                    <button
                      onClick={() => {
                        // console.log('ddddd->', feedback);
                        let obj = {
                          feedbackText: feedback,
                          user: user.id,
                          warehouse: parseInt(warehouseId),
                        };

                        axios
                          .post('/api/v1/feedback', obj)
                          .then((Response) => {
                            let res = JSON.parse(Response.data);

                            if (res.statusCode === 200) {
                              setThankYou('Thank You for your feedback');
                              setTimeout(() => {
                                setModalIsOpen(false);
                                setThankYou(null);
                              }, 3000);

                              // alert("Thank You!")
                            } else {
                              // setError1(res.message)
                            }
                          })
                          .catch((error) => {
                            // console.log(error);
                            setModalIsOpen(false);
                          });
                      }}
                      className='btn btn-primary px-4'
                    >
                      Ok
                    </button>
                    <button
                      onClick={() => setModalIsOpen(false)}
                      className='btn btn-primary px-3 ml-4'
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <>{thankYou}</>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={modalIsOpen2} centered>
          <Modal.Body className='p-0'>
            <div className='modal-content custom-modal-content'>
              <div className='modal-body custom-modal-body pt-0'>
                <div className='text-center mt-4'>
                  <h6 className='h6-max-xxs font-weight-medium'>Waresheet Created Successfully!</h6>
                </div>
              </div>
              <div className='col-sm-8 mx-auto mb-4'>
                <button
                  onClick={() => {
                    loadwaresheet();

                    setModalIsOpen2(false);
                    setSaveWaresheet(true);
                  }}
                  className='btn btn-deep-primary btn-block mb-4'
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={modalIsOpen3} centered>
          <Modal.Body className='p-0'>
            <div className='modal-content custom-modal-content'>
              <div className='modal-body custom-modal-body pt-0'>
                <div className='text-center mt-4'>
                  <h6 className='h6-max-xxs font-weight-medium'>
                    Images Added To Waresheet Successfully!
                  </h6>
                  {/* <h6 className="h6-max-xxs font-weight-medium">Please Login again.</h6> */}
                </div>
              </div>
              <div className='col-sm-8 mx-auto mb-4'>
                <button
                  onClick={() => {
                    setModalIsOpen3(false);
                    loadwaresheet();
                    setSaveWaresheet(false);
                    setShow(false);
                  }}
                  className='btn btn-deep-primary btn-block mb-4'
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div
          className='modal px-0'
          id='signin-modal'
          tabindex='-1'
          role='dialog'
          aria-labelledby='signin-modalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-xl' role='document'>
            <div className='modal-content border-0'>
              <div className='modal-body py-0 px-sm-3 px-2'>
                <div className='row'>
                  <div className='col-lg-8 modal-body-right-content pt-lg-2 pt-4 pb-4 px-0 order-lg-2'>
                    <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    <div className='card-body py-0'>
                      <ul
                        className='nav nav-pills common-tabs mb-3'
                        id='signin-tab'
                        role='tablist'
                        data-getelement='#modal-body-left-content'
                      >
                        <li className='nav-item'>
                          <a
                            className='nav-link h5 active'
                            id='signin-customer-tab'
                            data-toggle='pill'
                            href='.signin-customer'
                            role='tab'
                            aria-controls='signin-customer'
                            aria-selected='true'
                          >
                            customer
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className='nav-link h5'
                            id='signin-space-provider-tab'
                            data-toggle='pill'
                            href='.signin-space-provider'
                            role='tab'
                            aria-controls='signin-space-provider'
                            aria-selected='false'
                          >
                            Space provider
                          </a>
                        </li>
                      </ul>
                      <div className='tab-content px-3' id='signin-tabContent'>
                        <div
                          className='tab-pane fade show active signin-customer'
                          id='signin-customer'
                          role='tabpanel'
                          aria-labelledby='signin-customer-tab'
                        >
                          <form action=''>
                            <div className='row'>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='customerfirstname'>
                                  First Name <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='customerfirstname'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='customerlastname'>
                                  Last Name <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='customerlastname'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='customercontactnumber'>
                                  Contact Number
                                  <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='customercontactnumber'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='customeremailid'>
                                  Email ID <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='customeremailid'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='customerpassword'>
                                  Password<sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='customerpassword'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='customerconfirmpassword'>
                                  Confirm Password
                                  <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='customerconfirmpassword'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-12 form-group mb-3'>
                                <p className='mb-2'>Type</p>
                                <div className='row'>
                                  <div className='col-auto'>
                                    <div className='form-check common-radio-inline'>
                                      <input
                                        className='common-radio-input'
                                        type='radio'
                                        name='customertypeOptions'
                                        id='customertype1'
                                        value='option1'
                                        hidden
                                      />
                                      <label
                                        className='common-radio-label pl-2'
                                        htmlFor='customertype1'
                                      >
                                        Individual
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='form-check common-radio-inline'>
                                      <input
                                        className='common-radio-input'
                                        type='radio'
                                        name='customertypeOptions'
                                        id='customertype2'
                                        value='option2'
                                        hidden
                                      />
                                      <label
                                        className='common-radio-label pl-2'
                                        htmlFor='customertype2'
                                      >
                                        Broker
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='form-check common-radio-inline'>
                                      <input
                                        className='common-radio-input'
                                        type='radio'
                                        name='customertypeOptions'
                                        id='customertype3'
                                        value='option3'
                                        hidden
                                      />
                                      <label
                                        className='common-radio-label pl-2'
                                        htmlFor='customertype3'
                                      >
                                        Organisation
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-6 form-group'>
                                <label htmlFor='customercompanyname'>Company Name</label>
                                <input
                                  type='text'
                                  id='customercompanyname'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-12'>
                                <div className='common-checkbox form-check'>
                                  <input
                                    type='checkbox'
                                    className='common-checkbox-input form-check-input'
                                    id='exampleCheck1'
                                  />
                                  <label
                                    className='common-checkbox-label form-check-label mb-2 pl-2'
                                    htmlFor='exampleCheck1'
                                  >
                                    I agree to the{' '}
                                    <a
                                      href='terms-and-conditions.html'
                                      className='btn-link btn-link-dark-primary'
                                    >
                                      terms and conditions.
                                    </a>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button type='submit' className='btn btn-deep-primary my-3'>
                              Submit
                            </button>
                            <p className='mb-0'>
                              Already have an Account?{' '}
                              <a href='#fb' className='btn-line-dark-primary py-1'>
                                Sign In
                              </a>
                            </p>
                          </form>
                        </div>
                        <div
                          className='tab-pane fade signin-space-provider'
                          id='signin-space-provider'
                          role='tabpanel'
                          aria-labelledby='signin-space-provider-tab'
                        >
                          <form action=''>
                            <div className='row'>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='spaceproviderfirstname'>
                                  First Name <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='spaceproviderfirstname'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='spaceproviderlastname'>
                                  Last Name <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='spaceproviderlastname'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='spaceprovidercontactnumber'>
                                  Contact Number
                                  <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='spaceprovidercontactnumber'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='spaceprovideremailid'>
                                  Email ID <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='spaceprovideremailid'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='spaceproviderpassword'>
                                  Password<sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='spaceproviderpassword'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-md-6 form-group'>
                                <label htmlFor='spaceproviderconfirmpassword'>
                                  Confirm Password
                                  <sup className='text-danger'>*</sup>
                                </label>
                                <input
                                  type='text'
                                  id='spaceproviderconfirmpassword'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-12 form-group mb-3'>
                                <p className='mb-2'>Type</p>
                                <div className='row'>
                                  <div className='col-auto'>
                                    <div className='form-check common-radio-inline'>
                                      <input
                                        className='common-radio-input'
                                        type='radio'
                                        name='spaceprovidertypeOptions'
                                        id='spaceprovidertype1'
                                        value='option1'
                                        hidden
                                      />
                                      <label
                                        className='common-radio-label pl-2'
                                        htmlFor='spaceprovidertype1'
                                      >
                                        Individual
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='form-check common-radio-inline'>
                                      <input
                                        className='common-radio-input'
                                        type='radio'
                                        name='spaceprovidertypeOptions'
                                        id='spaceprovidertype2'
                                        value='option2'
                                        hidden
                                      />
                                      <label
                                        className='common-radio-label pl-2'
                                        htmlFor='spaceprovidertype2'
                                      >
                                        Broker
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='form-check common-radio-inline'>
                                      <input
                                        className='common-radio-input'
                                        type='radio'
                                        name='spaceprovidertypeOptions'
                                        id='spaceprovidertype3'
                                        value='option3'
                                        hidden
                                      />
                                      <label
                                        className='common-radio-label pl-2'
                                        htmlFor='spaceprovidertype3'
                                      >
                                        Organisation
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-6 form-group'>
                                <label htmlFor='spaceprovidercompanyname'>Company Name</label>
                                <input
                                  type='text'
                                  id='spaceprovidercompanyname'
                                  className='form-control form-control-sm'
                                  placeholder='Type here'
                                />
                              </div>
                              <div className='col-12'>
                                <div className='common-checkbox form-check'>
                                  <input
                                    type='checkbox'
                                    className='common-checkbox-input form-check-input'
                                    id='spaceprovideragree'
                                  />
                                  <label
                                    className='common-checkbox-label form-check-label mb-2 pl-2'
                                    htmlFor='spaceprovideragree'
                                  >
                                    I agree to the{' '}
                                    <a
                                      href='terms-and-conditions.html'
                                      className='btn-link btn-link-dark-primary'
                                    >
                                      terms and conditions.
                                    </a>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button type='submit' className='btn btn-deep-primary my-3'>
                              Submit
                            </button>
                            <p className='mb-0'>
                              Already have an Account?{' '}
                              <a href='#fb' className='btn-line-dark-primary py-1'>
                                Sign In
                              </a>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 modal-body-left-content overlay deep-primary-overlay py-4 px-4 order-lg-1'>
                    <div className='row' id='modal-body-left-content'>
                      <div className='col-lg-12 tab-pane d-block signin-customer'>
                        <div className='row'>
                          <div className='col-lg-12 col-sm-6 border-bottom-line overlay-content p-3 signin-customer'>
                            <h5 className='mb-4'>Why Choose Us?</h5>
                            <ul className='pl-3'>
                              <li className='mb-1'>Boosting of the Revenue!</li>
                              <li className='mb-1'>Maximum Utilization Of Space!</li>
                              <li className='mb-1'>Increase Your Space Occupancy Level!</li>
                              <li className='mb-1'>Increased Visibility Of Your Assets!</li>
                              <li className='mb-1'>Tech Support & Skill Development!</li>
                            </ul>
                          </div>
                          <div className='col-lg-12 col-sm-6 overlay-content p-3'>
                            <h5 className='mb-4'>Who can list with Warehousity?</h5>
                            <ul className='pl-3'>
                              <li className='mb-1'>Boosting of the Revenue!</li>
                              <li className='mb-1'>Maximum Utilization Of Space!</li>
                              <li className='mb-1'>Increase Your Space Occupancy Level!</li>
                              <li className='mb-1'>Increased Visibility Of Your Assets!</li>
                              <li className='mb-1'>Tech Support & Skill Development!</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 tab-pane d-none signin-space-provider'>
                        <div className='row'>
                          <div className='col-lg-12 col-sm-6 border-bottom-line overlay-content p-3 signin-customer'>
                            <h5 className='mb-4'>Why Choose Us?</h5>
                            <ul className='pl-3'>
                              <li className='mb-1'>Boosting of the Revenue!</li>
                              <li className='mb-1'>Maximum Utilization Of Space!</li>
                              <li className='mb-1'>Increase Your Space Occupancy Level!</li>
                              <li className='mb-1'>Increased Visibility Of Your Assets!</li>
                              <li className='mb-1'>Tech Support & Skill Development!</li>
                            </ul>
                          </div>
                          <div className='col-lg-12 col-sm-6 overlay-content p-3'>
                            <h5 className='mb-4'>Who can list with Warehousity?</h5>
                            <ul className='pl-3'>
                              <li className='mb-1'>Boosting of the Revenue!</li>
                              <li className='mb-1'>Maximum Utilization Of Space!</li>
                              <li className='mb-1'>Increase Your Space Occupancy Level!</li>
                              <li className='mb-1'>Increased Visibility Of Your Assets!</li>
                              <li className='mb-1'>Tech Support & Skill Development!</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sign In Modal */}
        <div
          className='modal px-0'
          id='create-account-modal'
          tabindex='-1'
          role='dialog'
          aria-labelledby='create-account-modalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content border-0'>
              <div className='modal-body py-0'>
                <div className='row px-3'>
                  <div className='modal-body-right-content py-lg-4 my-1 p-sm-4 p-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                        <div>
                          <h5 className='mb-4 modal-title'>Sign up to check price</h5>
                        </div>
                      </div>
                    </div>
                    <form action=''>
                      <div className='row'>
                        <div className='col-sm-6 form-group mb-3'>
                          <label htmlFor='spaceproviderfirstname'>
                            First Name <sup className='text-danger'>*</sup>
                          </label>
                          <input
                            type='text'
                            id='spaceproviderfirstname'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-sm-6 form-group mb-3'>
                          <label htmlFor='spaceproviderlastname'>
                            Last Name <sup className='text-danger'>*</sup>
                          </label>
                          <input
                            type='text'
                            id='spaceproviderlastname'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-sm-6 form-group mb-3'>
                          <label htmlFor='spaceprovidercontactnumber'>
                            Contact Number<sup className='text-danger'>*</sup>
                          </label>
                          <input
                            type='text'
                            id='spaceprovidercontactnumber'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-sm-6 form-group mb-3'>
                          <label htmlFor='spaceprovideremailid'>
                            Email ID <sup className='text-danger'>*</sup>
                          </label>
                          <input
                            type='text'
                            id='spaceprovideremailid'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-sm-6 form-group mb-3'>
                          <label htmlFor='spaceproviderpassword'>
                            Password<sup className='text-danger'>*</sup>
                          </label>
                          <input
                            type='text'
                            id='spaceproviderpassword'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-sm-6 form-group mb-3'>
                          <label htmlFor='spaceproviderconfirmpassword'>
                            Confirm Password<sup className='text-danger'>*</sup>
                          </label>
                          <input
                            type='text'
                            id='spaceproviderconfirmpassword'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-12 form-group mb-3'>
                          <p className='mb-2'>Type</p>
                          <div className='row'>
                            <div className='col-auto'>
                              <div className='form-check common-radio-inline'>
                                <input
                                  className='common-radio-input'
                                  type='radio'
                                  name='spaceprovidertypeOptions'
                                  id='spaceprovidertype1'
                                  value='option1'
                                  hidden
                                />
                                <label
                                  className='common-radio-label pl-2'
                                  htmlFor='spaceprovidertype1'
                                >
                                  Individual
                                </label>
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='form-check common-radio-inline'>
                                <input
                                  className='common-radio-input'
                                  type='radio'
                                  name='spaceprovidertypeOptions'
                                  id='spaceprovidertype2'
                                  value='option2'
                                  hidden
                                />
                                <label
                                  className='common-radio-label pl-2'
                                  htmlFor='spaceprovidertype2'
                                >
                                  Broker
                                </label>
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='form-check common-radio-inline'>
                                <input
                                  className='common-radio-input'
                                  type='radio'
                                  name='spaceprovidertypeOptions'
                                  id='spaceprovidertype3'
                                  value='option3'
                                  hidden
                                />
                                <label
                                  className='common-radio-label pl-2'
                                  htmlFor='spaceprovidertype3'
                                >
                                  Organisation
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6 form-group'>
                          <label htmlFor='spaceprovidercompanyname'>Company Name</label>
                          <input
                            type='text'
                            id='spaceprovidercompanyname'
                            className='form-control form-control-sm'
                            placeholder='Type here'
                          />
                        </div>
                        <div className='col-12'>
                          <div className='common-checkbox form-check'>
                            <input
                              type='checkbox'
                              className='common-checkbox-input form-check-input'
                              id='spaceprovideragree'
                            />
                            <label
                              className='common-checkbox-label form-check-label mb-2 pl-2'
                              htmlFor='spaceprovideragree'
                            >
                              I agree to the{' '}
                              <a
                                href='terms-and-conditions.html'
                                className='btn-link btn-link-deep-primary'
                              >
                                terms and conditions.
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <button type='submit' className='btn btn-deep-primary my-3'>
                        Submit
                      </button>
                      <p className='mb-0'>
                        Already have an Account?{' '}
                        <a href='#fb' className='btn-line-deep-primary py-1'>
                          Sign In
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ===============  Check Price  ===================== */}

        <Modal show={checkPrice} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
          <Modal.Body className='m-0 p-0'>
            <PriceGraph
              setCheckPrice={setCheckPrice}
              addCart={addCart}
              data={data}
              location={items?.warehouseDetailPage?.warehouseContactDetailInfo?.address?.state}
            />
          </Modal.Body>
        </Modal>

        {/* ==================================== */}
        <Modal
          show={mobilemodal}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          closeButton
        >
          <Modal.Body className='m-0 p-0'>
            {/* <div className="modal-dialog" role="document"> */}
            <div className='modal-content border-0'>
              <div className='modal-body py-0'>
                <div className='row px-3'>
                  <div className='col-12 py-lg-4 my-3 p-sm-5 p-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <button
                          onClick={() => setmobilemodal(false)}
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                        <div>
                          <div className='img-holder text-center'>
                            <img className='img-fluid' src='/assets/images/logo.png' alt='logo' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className='row mt-4'>
                        <div className='form-group col-12 mt-2'>
                          <label htmlFor='mobilenumber' className='font-heading'>
                            Enter Mobile Number
                          </label>
                          <div className='input-group mb-3 d-flex phone-group rounded-0'>
                            <div className='input-group-prepend'>
                              <span
                                className='input-group-text p-0 bg-none'
                                id='mobile-number-group'
                              >
                                <select
                                  className='form-control form-control-sm custom-select border-0'
                                  name='countryCode'
                                  id=''
                                >
                                  <option data-countryCode='IN' value='91'>
                                    +91
                                  </option>
                                  <option data-countryCode='US' value='1'>
                                    +1
                                  </option>
                                  <optgroup label='Other countries'>
                                    <option data-countryCode='DZ' value='213'>
                                      +213
                                    </option>
                                    <option data-countryCode='AD' value='376'>
                                      +376
                                    </option>
                                    <option data-countryCode='AO' value='244'>
                                      +244
                                    </option>
                                    <option data-countryCode='AI' value='1264'>
                                      +1264
                                    </option>
                                    <option data-countryCode='AG' value='1268'>
                                      +1268
                                    </option>
                                    <option data-countryCode='AR' value='54'>
                                      +54
                                    </option>
                                    <option data-countryCode='AM' value='374'>
                                      +374
                                    </option>
                                    <option data-countryCode='AW' value='297'>
                                      +297
                                    </option>
                                    <option data-countryCode='AU' value='61'>
                                      +61
                                    </option>
                                    <option data-countryCode='AT' value='43'>
                                      +43
                                    </option>
                                    <option data-countryCode='AZ' value='994'>
                                      +994
                                    </option>
                                    <option data-countryCode='BS' value='1242'>
                                      +1242
                                    </option>
                                    <option data-countryCode='BH' value='973'>
                                      +973
                                    </option>
                                    <option data-countryCode='BD' value='880'>
                                      +880
                                    </option>
                                    <option data-countryCode='BB' value='1246'>
                                      +1246
                                    </option>
                                    <option data-countryCode='BY' value='375'>
                                      +375
                                    </option>
                                    <option data-countryCode='BE' value='32'>
                                      +32
                                    </option>
                                    <option data-countryCode='BZ' value='501'>
                                      +501
                                    </option>
                                    <option data-countryCode='BJ' value='229'>
                                      +229
                                    </option>
                                    <option data-countryCode='BM' value='1441'>
                                      +1441
                                    </option>
                                    <option data-countryCode='BT' value='975'>
                                      +975
                                    </option>
                                    <option data-countryCode='BO' value='591'>
                                      +591
                                    </option>
                                    <option data-countryCode='BA' value='387'>
                                      +387
                                    </option>
                                    <option data-countryCode='BW' value='267'>
                                      +267
                                    </option>
                                    <option data-countryCode='BR' value='55'>
                                      +55
                                    </option>
                                    <option data-countryCode='BN' value='673'>
                                      +673
                                    </option>
                                    <option data-countryCode='BG' value='359'>
                                      +359
                                    </option>
                                    <option data-countryCode='BF' value='226'>
                                      +226
                                    </option>
                                    <option data-countryCode='BI' value='257'>
                                      +257
                                    </option>
                                    <option data-countryCode='KH' value='855'>
                                      +855
                                    </option>
                                    <option data-countryCode='CM' value='237'>
                                      +237
                                    </option>
                                    <option data-countryCode='CA' value='1'>
                                      +1
                                    </option>
                                    <option data-countryCode='CV' value='238'>
                                      +238
                                    </option>
                                    <option data-countryCode='KY' value='1345'>
                                      +1345
                                    </option>
                                    <option data-countryCode='CF' value='236'>
                                      +236
                                    </option>
                                    <option data-countryCode='CL' value='56'>
                                      +56
                                    </option>
                                    <option data-countryCode='CN' value='86'>
                                      +86
                                    </option>
                                    <option data-countryCode='CO' value='57'>
                                      +57
                                    </option>
                                    <option data-countryCode='KM' value='269'>
                                      +269
                                    </option>
                                    <option data-countryCode='CG' value='242'>
                                      +242
                                    </option>
                                    <option data-countryCode='CK' value='682'>
                                      +682
                                    </option>
                                    <option data-countryCode='CR' value='506'>
                                      +506
                                    </option>
                                    <option data-countryCode='HR' value='385'>
                                      +385
                                    </option>
                                    <option data-countryCode='CU' value='53'>
                                      +53
                                    </option>
                                    <option data-countryCode='CY' value='90392'>
                                      +90392
                                    </option>
                                    <option data-countryCode='CY' value='357'>
                                      +357
                                    </option>
                                    <option data-countryCode='CZ' value='42'>
                                      +42
                                    </option>
                                    <option data-countryCode='DK' value='45'>
                                      +45
                                    </option>
                                    <option data-countryCode='DJ' value='253'>
                                      +253
                                    </option>
                                    <option data-countryCode='DM' value='1809'>
                                      +1809
                                    </option>
                                    <option data-countryCode='DO' value='1809'>
                                      +1809
                                    </option>
                                    <option data-countryCode='EC' value='593'>
                                      +593
                                    </option>
                                    <option data-countryCode='EG' value='20'>
                                      +20
                                    </option>
                                    <option data-countryCode='SV' value='503'>
                                      +503
                                    </option>
                                    <option data-countryCode='GQ' value='240'>
                                      +240
                                    </option>
                                    <option data-countryCode='ER' value='291'>
                                      +291
                                    </option>
                                    <option data-countryCode='EE' value='372'>
                                      +372
                                    </option>
                                    <option data-countryCode='ET' value='251'>
                                      +251
                                    </option>
                                    <option data-countryCode='FK' value='500'>
                                      +500
                                    </option>
                                    <option data-countryCode='FO' value='298'>
                                      +298
                                    </option>
                                    <option data-countryCode='FJ' value='679'>
                                      +679
                                    </option>
                                    <option data-countryCode='FI' value='358'>
                                      +358
                                    </option>
                                    <option data-countryCode='FR' value='33'>
                                      +33
                                    </option>
                                    <option data-countryCode='GF' value='594'>
                                      +594
                                    </option>
                                    <option data-countryCode='PF' value='689'>
                                      +689
                                    </option>
                                    <option data-countryCode='GA' value='241'>
                                      +241
                                    </option>
                                    <option data-countryCode='GM' value='220'>
                                      +220
                                    </option>
                                    <option data-countryCode='GE' value='7880'>
                                      +7880
                                    </option>
                                    <option data-countryCode='DE' value='49'>
                                      +49
                                    </option>
                                    <option data-countryCode='GH' value='233'>
                                      +233
                                    </option>
                                    <option data-countryCode='GI' value='350'>
                                      +350
                                    </option>
                                    <option data-countryCode='GR' value='30'>
                                      +30
                                    </option>
                                    <option data-countryCode='GL' value='299'>
                                      +299
                                    </option>
                                    <option data-countryCode='GD' value='1473'>
                                      +1473
                                    </option>
                                    <option data-countryCode='GP' value='590'>
                                      +590
                                    </option>
                                    <option data-countryCode='GU' value='671'>
                                      +671
                                    </option>
                                    <option data-countryCode='GT' value='502'>
                                      +502
                                    </option>
                                    <option data-countryCode='GN' value='224'>
                                      +224
                                    </option>
                                    <option data-countryCode='GW' value='245'>
                                      +245
                                    </option>
                                    <option data-countryCode='GY' value='592'>
                                      +592
                                    </option>
                                    <option data-countryCode='HT' value='509'>
                                      +509
                                    </option>
                                    <option data-countryCode='HN' value='504'>
                                      +504
                                    </option>
                                    <option data-countryCode='HK' value='852'>
                                      +852
                                    </option>
                                    <option data-countryCode='HU' value='36'>
                                      +36
                                    </option>
                                    <option data-countryCode='IS' value='354'>
                                      +354
                                    </option>
                                    <option data-countryCode='GB' value='44'>
                                      +44
                                    </option>

                                    <option data-countryCode='ID' value='62'>
                                      +62
                                    </option>
                                    <option data-countryCode='IR' value='98'>
                                      +98
                                    </option>
                                    <option data-countryCode='IQ' value='964'>
                                      +964
                                    </option>
                                    <option data-countryCode='IE' value='353'>
                                      +353
                                    </option>
                                    <option data-countryCode='IL' value='972'>
                                      +972
                                    </option>
                                    <option data-countryCode='IT' value='39'>
                                      +39
                                    </option>
                                    <option data-countryCode='JM' value='1876'>
                                      +1876
                                    </option>
                                    <option data-countryCode='JP' value='81'>
                                      +81
                                    </option>
                                    <option data-countryCode='JO' value='962'>
                                      +962
                                    </option>
                                    <option data-countryCode='KZ' value='7'>
                                      +7
                                    </option>
                                    <option data-countryCode='KE' value='254'>
                                      +254
                                    </option>
                                    <option data-countryCode='KI' value='686'>
                                      +686
                                    </option>
                                    <option data-countryCode='KP' value='850'>
                                      +850
                                    </option>
                                    <option data-countryCode='KR' value='82'>
                                      +82
                                    </option>
                                    <option data-countryCode='KW' value='965'>
                                      +965
                                    </option>
                                    <option data-countryCode='KG' value='996'>
                                      +996
                                    </option>
                                    <option data-countryCode='LA' value='856'>
                                      +856
                                    </option>
                                    <option data-countryCode='LV' value='371'>
                                      +371
                                    </option>
                                    <option data-countryCode='LB' value='961'>
                                      +961
                                    </option>
                                    <option data-countryCode='LS' value='266'>
                                      +266
                                    </option>
                                    <option data-countryCode='LR' value='231'>
                                      +231
                                    </option>
                                    <option data-countryCode='LY' value='218'>
                                      +218
                                    </option>
                                    <option data-countryCode='LI' value='417'>
                                      +417
                                    </option>
                                    <option data-countryCode='LT' value='370'>
                                      +370
                                    </option>
                                    <option data-countryCode='LU' value='352'>
                                      +352
                                    </option>
                                    <option data-countryCode='MO' value='853'>
                                      +853
                                    </option>
                                    <option data-countryCode='MK' value='389'>
                                      +389
                                    </option>
                                    <option data-countryCode='MG' value='261'>
                                      +261
                                    </option>
                                    <option data-countryCode='MW' value='265'>
                                      +265
                                    </option>
                                    <option data-countryCode='MY' value='60'>
                                      +60
                                    </option>
                                    <option data-countryCode='MV' value='960'>
                                      +960
                                    </option>
                                    <option data-countryCode='ML' value='223'>
                                      +223
                                    </option>
                                    <option data-countryCode='MT' value='356'>
                                      +356
                                    </option>
                                    <option data-countryCode='MH' value='692'>
                                      +692
                                    </option>
                                    <option data-countryCode='MQ' value='596'>
                                      +596
                                    </option>
                                    <option data-countryCode='MR' value='222'>
                                      +222
                                    </option>
                                    <option data-countryCode='YT' value='269'>
                                      +269
                                    </option>
                                    <option data-countryCode='MX' value='52'>
                                      +52
                                    </option>
                                    <option data-countryCode='FM' value='691'>
                                      +691
                                    </option>
                                    <option data-countryCode='MD' value='373'>
                                      +373
                                    </option>
                                    <option data-countryCode='MC' value='377'>
                                      +377
                                    </option>
                                    <option data-countryCode='MN' value='976'>
                                      +976
                                    </option>
                                    <option data-countryCode='MS' value='1664'>
                                      +1664
                                    </option>
                                    <option data-countryCode='MA' value='212'>
                                      +212
                                    </option>
                                    <option data-countryCode='MZ' value='258'>
                                      +258
                                    </option>
                                    <option data-countryCode='MN' value='95'>
                                      +95
                                    </option>
                                    <option data-countryCode='NA' value='264'>
                                      +264
                                    </option>
                                    <option data-countryCode='NR' value='674'>
                                      +674
                                    </option>
                                    <option data-countryCode='NP' value='977'>
                                      +977
                                    </option>
                                    <option data-countryCode='NL' value='31'>
                                      +31
                                    </option>
                                    <option data-countryCode='NC' value='687'>
                                      +687
                                    </option>
                                    <option data-countryCode='NZ' value='64'>
                                      +64
                                    </option>
                                    <option data-countryCode='NI' value='505'>
                                      +505
                                    </option>
                                    <option data-countryCode='NE' value='227'>
                                      +227
                                    </option>
                                    <option data-countryCode='NG' value='234'>
                                      +234
                                    </option>
                                    <option data-countryCode='NU' value='683'>
                                      +683
                                    </option>
                                    <option data-countryCode='NF' value='672'>
                                      +672
                                    </option>
                                    <option data-countryCode='NP' value='670'>
                                      +670
                                    </option>
                                    <option data-countryCode='NO' value='47'>
                                      +47
                                    </option>
                                    <option data-countryCode='OM' value='968'>
                                      +968
                                    </option>
                                    <option data-countryCode='PW' value='680'>
                                      +680
                                    </option>
                                    <option data-countryCode='PA' value='507'>
                                      +507
                                    </option>
                                    <option data-countryCode='PG' value='675'>
                                      +675
                                    </option>
                                    <option data-countryCode='PY' value='595'>
                                      +595
                                    </option>
                                    <option data-countryCode='PE' value='51'>
                                      +51
                                    </option>
                                    <option data-countryCode='PH' value='63'>
                                      +63
                                    </option>
                                    <option data-countryCode='PL' value='48'>
                                      +48
                                    </option>
                                    <option data-countryCode='PT' value='351'>
                                      +351
                                    </option>
                                    <option data-countryCode='PR' value='1787'>
                                      +1787
                                    </option>
                                    <option data-countryCode='QA' value='974'>
                                      +974
                                    </option>
                                    <option data-countryCode='RE' value='262'>
                                      +262
                                    </option>
                                    <option data-countryCode='RO' value='40'>
                                      +40
                                    </option>
                                    <option data-countryCode='RU' value='7'>
                                      +7
                                    </option>
                                    <option data-countryCode='RW' value='250'>
                                      +250
                                    </option>
                                    <option data-countryCode='SM' value='378'>
                                      +378
                                    </option>
                                    <option data-countryCode='ST' value='239'>
                                      +239
                                    </option>
                                    <option data-countryCode='SA' value='966'>
                                      +966
                                    </option>
                                    <option data-countryCode='SN' value='221'>
                                      +221
                                    </option>
                                    <option data-countryCode='CS' value='381'>
                                      +381
                                    </option>
                                    <option data-countryCode='SC' value='248'>
                                      +248
                                    </option>
                                    <option data-countryCode='SL' value='232'>
                                      +232
                                    </option>
                                    <option data-countryCode='SG' value='65'>
                                      +65
                                    </option>
                                    <option data-countryCode='SK' value='421'>
                                      +421
                                    </option>
                                    <option data-countryCode='SI' value='386'>
                                      +386
                                    </option>
                                    <option data-countryCode='SB' value='677'>
                                      +677
                                    </option>
                                    <option data-countryCode='SO' value='252'>
                                      +252
                                    </option>
                                    <option data-countryCode='ZA' value='27'>
                                      +27
                                    </option>
                                    <option data-countryCode='ES' value='34'>
                                      +34
                                    </option>
                                    <option data-countryCode='LK' value='94'>
                                      +94
                                    </option>
                                    <option data-countryCode='SH' value='290'>
                                      +290
                                    </option>
                                    <option data-countryCode='KN' value='1869'>
                                      +1869
                                    </option>
                                    <option data-countryCode='SC' value='1758'>
                                      +1758
                                    </option>
                                    <option data-countryCode='SD' value='249'>
                                      +249
                                    </option>
                                    <option data-countryCode='SR' value='597'>
                                      +597
                                    </option>
                                    <option data-countryCode='SZ' value='268'>
                                      +268
                                    </option>
                                    <option data-countryCode='SE' value='46'>
                                      +46
                                    </option>
                                    <option data-countryCode='CH' value='41'>
                                      +41
                                    </option>
                                    <option data-countryCode='SI' value='963'>
                                      +963
                                    </option>
                                    <option data-countryCode='TW' value='886'>
                                      +886
                                    </option>
                                    <option data-countryCode='TJ' value='7'>
                                      +7
                                    </option>
                                    <option data-countryCode='TH' value='66'>
                                      +66
                                    </option>
                                    <option data-countryCode='TG' value='228'>
                                      +228
                                    </option>
                                    <option data-countryCode='TO' value='676'>
                                      +676
                                    </option>
                                    <option data-countryCode='TT' value='1868'>
                                      +1868
                                    </option>
                                    <option data-countryCode='TN' value='216'>
                                      +216
                                    </option>
                                    <option data-countryCode='TR' value='90'>
                                      +90
                                    </option>
                                    <option data-countryCode='TM' value='7'>
                                      +7
                                    </option>
                                    <option data-countryCode='TM' value='993'>
                                      +993
                                    </option>
                                    <option data-countryCode='TC' value='1649'>
                                      +1649
                                    </option>
                                    <option data-countryCode='TV' value='688'>
                                      +688
                                    </option>
                                    <option data-countryCode='UG' value='256'>
                                      +256
                                    </option>
                                    <option data-countryCode='UZ' value='7'>
                                      +7
                                    </option>
                                    <option data-countryCode='VU' value='678'>
                                      +678
                                    </option>
                                    <option data-countryCode='VA' value='379'>
                                      +379
                                    </option>
                                    <option data-countryCode='VE' value='58'>
                                      +58
                                    </option>
                                    <option data-countryCode='VN' value='84'>
                                      +84
                                    </option>
                                    <option data-countryCode='VG' value='84'>
                                      +1284
                                    </option>
                                    <option data-countryCode='VI' value='84'>
                                      +1340
                                    </option>
                                    <option data-countryCode='WF' value='681'>
                                      +681
                                    </option>
                                    <option data-countryCode='YE' value='969'>
                                      (+969
                                    </option>
                                    <option data-countryCode='YE' value='967'>
                                      (+967
                                    </option>
                                    <option data-countryCode='ZM' value='260'>
                                      +260
                                    </option>
                                    <option data-countryCode='ZW' value='263'>
                                      +263
                                    </option>
                                  </optgroup>
                                </select>
                              </span>
                            </div>
                            <input
                              name='phoneNumber'
                              id='phoneNumber'
                              title='Please use a 10 digit telephone number with no dashes or dots'
                              pattern='[0-9]{10}'
                              onInput={maxLengthCheck}
                              onKeyDown={(e) => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                              maxLength={10}
                              minLength={10}
                              value={email.mobile}
                              required={true}
                              onChange={handleChange5('mobile')}
                              type='number'
                              className='form-control form-control-sm'
                              placeholder='Mobile Number (10 digits)'
                            />
                          </div>
                        </div>
                      </div>
                      <button type='submit' className='btn btn-deep-primary rounded-0 btn-block'>
                        Continue
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Mobile Number  */}

        {/* Verify OTP  */}
        <div
          className='modal px-0'
          id='verify-otp-modal'
          tabindex='-1'
          role='dialog'
          aria-labelledby='verify-otp-modalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content border-0'>
              <div className='modal-body py-0'>
                <div className='row px-3'>
                  <div className='col-12 py-lg-4 my-3 p-sm-5 p-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <div>
                          <div className='img-holder text-center'>
                            <img className='img-fluid' src='/assets/images/logo.png' alt='logo' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <form action=''>
                      <div className='row mt-4'>
                        <div className='form-group col-12 mt-2 mb-0'>
                          <h5 className='text-center'>Verify OTP</h5>
                          <label htmlFor='mobilenumber' className='font-heading text-center mb-3'>
                            We have sent a verification code on our registered mobile number
                          </label>
                          <div className='disabled-true inputs'>
                            <div className='input-group d-flex phone-group rounded-0'>
                              <div className='input-group-prepend'>
                                <span
                                  className='input-group-text p-0 bg-none'
                                  id='mobile-number-group'
                                >
                                  <select
                                    className='form-control form-control-sm custom-select border-0 rounded-0'
                                    name='countryCode'
                                    id=''
                                  >
                                    <option data-countryCode='GB' value='44'>
                                      +44
                                    </option>
                                    <option data-countryCode='US' value='1'>
                                      +1
                                    </option>
                                    <optgroup label='Other countries'>
                                      <option data-countryCode='DZ' value='213'>
                                        +213
                                      </option>
                                      <option data-countryCode='AD' value='376'>
                                        +376
                                      </option>
                                      <option data-countryCode='AO' value='244'>
                                        +244
                                      </option>
                                      <option data-countryCode='AI' value='1264'>
                                        +1264
                                      </option>
                                      <option data-countryCode='AG' value='1268'>
                                        +1268
                                      </option>
                                      <option data-countryCode='AR' value='54'>
                                        +54
                                      </option>
                                      <option data-countryCode='AM' value='374'>
                                        +374
                                      </option>
                                      <option data-countryCode='AW' value='297'>
                                        +297
                                      </option>
                                      <option data-countryCode='AU' value='61'>
                                        +61
                                      </option>
                                      <option data-countryCode='AT' value='43'>
                                        +43
                                      </option>
                                      <option data-countryCode='AZ' value='994'>
                                        +994
                                      </option>
                                      <option data-countryCode='BS' value='1242'>
                                        +1242
                                      </option>
                                      <option data-countryCode='BH' value='973'>
                                        +973
                                      </option>
                                      <option data-countryCode='BD' value='880'>
                                        +880
                                      </option>
                                      <option data-countryCode='BB' value='1246'>
                                        +1246
                                      </option>
                                      <option data-countryCode='BY' value='375'>
                                        +375
                                      </option>
                                      <option data-countryCode='BE' value='32'>
                                        +32
                                      </option>
                                      <option data-countryCode='BZ' value='501'>
                                        +501
                                      </option>
                                      <option data-countryCode='BJ' value='229'>
                                        +229
                                      </option>
                                      <option data-countryCode='BM' value='1441'>
                                        +1441
                                      </option>
                                      <option data-countryCode='BT' value='975'>
                                        +975
                                      </option>
                                      <option data-countryCode='BO' value='591'>
                                        +591
                                      </option>
                                      <option data-countryCode='BA' value='387'>
                                        +387
                                      </option>
                                      <option data-countryCode='BW' value='267'>
                                        +267
                                      </option>
                                      <option data-countryCode='BR' value='55'>
                                        +55
                                      </option>
                                      <option data-countryCode='BN' value='673'>
                                        +673
                                      </option>
                                      <option data-countryCode='BG' value='359'>
                                        +359
                                      </option>
                                      <option data-countryCode='BF' value='226'>
                                        +226
                                      </option>
                                      <option data-countryCode='BI' value='257'>
                                        +257
                                      </option>
                                      <option data-countryCode='KH' value='855'>
                                        +855
                                      </option>
                                      <option data-countryCode='CM' value='237'>
                                        +237
                                      </option>
                                      <option data-countryCode='CA' value='1'>
                                        +1
                                      </option>
                                      <option data-countryCode='CV' value='238'>
                                        +238
                                      </option>
                                      <option data-countryCode='KY' value='1345'>
                                        +1345
                                      </option>
                                      <option data-countryCode='CF' value='236'>
                                        +236
                                      </option>
                                      <option data-countryCode='CL' value='56'>
                                        +56
                                      </option>
                                      <option data-countryCode='CN' value='86'>
                                        +86
                                      </option>
                                      <option data-countryCode='CO' value='57'>
                                        +57
                                      </option>
                                      <option data-countryCode='KM' value='269'>
                                        +269
                                      </option>
                                      <option data-countryCode='CG' value='242'>
                                        +242
                                      </option>
                                      <option data-countryCode='CK' value='682'>
                                        +682
                                      </option>
                                      <option data-countryCode='CR' value='506'>
                                        +506
                                      </option>
                                      <option data-countryCode='HR' value='385'>
                                        +385
                                      </option>
                                      <option data-countryCode='CU' value='53'>
                                        +53
                                      </option>
                                      <option data-countryCode='CY' value='90392'>
                                        +90392
                                      </option>
                                      <option data-countryCode='CY' value='357'>
                                        +357
                                      </option>
                                      <option data-countryCode='CZ' value='42'>
                                        +42
                                      </option>
                                      <option data-countryCode='DK' value='45'>
                                        +45
                                      </option>
                                      <option data-countryCode='DJ' value='253'>
                                        +253
                                      </option>
                                      <option data-countryCode='DM' value='1809'>
                                        +1809
                                      </option>
                                      <option data-countryCode='DO' value='1809'>
                                        +1809
                                      </option>
                                      <option data-countryCode='EC' value='593'>
                                        +593
                                      </option>
                                      <option data-countryCode='EG' value='20'>
                                        +20
                                      </option>
                                      <option data-countryCode='SV' value='503'>
                                        +503
                                      </option>
                                      <option data-countryCode='GQ' value='240'>
                                        +240
                                      </option>
                                      <option data-countryCode='ER' value='291'>
                                        +291
                                      </option>
                                      <option data-countryCode='EE' value='372'>
                                        +372
                                      </option>
                                      <option data-countryCode='ET' value='251'>
                                        +251
                                      </option>
                                      <option data-countryCode='FK' value='500'>
                                        +500
                                      </option>
                                      <option data-countryCode='FO' value='298'>
                                        +298
                                      </option>
                                      <option data-countryCode='FJ' value='679'>
                                        +679
                                      </option>
                                      <option data-countryCode='FI' value='358'>
                                        +358
                                      </option>
                                      <option data-countryCode='FR' value='33'>
                                        +33
                                      </option>
                                      <option data-countryCode='GF' value='594'>
                                        +594
                                      </option>
                                      <option data-countryCode='PF' value='689'>
                                        +689
                                      </option>
                                      <option data-countryCode='GA' value='241'>
                                        +241
                                      </option>
                                      <option data-countryCode='GM' value='220'>
                                        +220
                                      </option>
                                      <option data-countryCode='GE' value='7880'>
                                        +7880
                                      </option>
                                      <option data-countryCode='DE' value='49'>
                                        +49
                                      </option>
                                      <option data-countryCode='GH' value='233'>
                                        +233
                                      </option>
                                      <option data-countryCode='GI' value='350'>
                                        +350
                                      </option>
                                      <option data-countryCode='GR' value='30'>
                                        +30
                                      </option>
                                      <option data-countryCode='GL' value='299'>
                                        +299
                                      </option>
                                      <option data-countryCode='GD' value='1473'>
                                        +1473
                                      </option>
                                      <option data-countryCode='GP' value='590'>
                                        +590
                                      </option>
                                      <option data-countryCode='GU' value='671'>
                                        +671
                                      </option>
                                      <option data-countryCode='GT' value='502'>
                                        +502
                                      </option>
                                      <option data-countryCode='GN' value='224'>
                                        +224
                                      </option>
                                      <option data-countryCode='GW' value='245'>
                                        +245
                                      </option>
                                      <option data-countryCode='GY' value='592'>
                                        +592
                                      </option>
                                      <option data-countryCode='HT' value='509'>
                                        +509
                                      </option>
                                      <option data-countryCode='HN' value='504'>
                                        +504
                                      </option>
                                      <option data-countryCode='HK' value='852'>
                                        +852
                                      </option>
                                      <option data-countryCode='HU' value='36'>
                                        +36
                                      </option>
                                      <option data-countryCode='IS' value='354'>
                                        +354
                                      </option>
                                      <option data-countryCode='IN' value='91'>
                                        +91
                                      </option>
                                      <option data-countryCode='ID' value='62'>
                                        +62
                                      </option>
                                      <option data-countryCode='IR' value='98'>
                                        +98
                                      </option>
                                      <option data-countryCode='IQ' value='964'>
                                        +964
                                      </option>
                                      <option data-countryCode='IE' value='353'>
                                        +353
                                      </option>
                                      <option data-countryCode='IL' value='972'>
                                        +972
                                      </option>
                                      <option data-countryCode='IT' value='39'>
                                        +39
                                      </option>
                                      <option data-countryCode='JM' value='1876'>
                                        +1876
                                      </option>
                                      <option data-countryCode='JP' value='81'>
                                        +81
                                      </option>
                                      <option data-countryCode='JO' value='962'>
                                        +962
                                      </option>
                                      <option data-countryCode='KZ' value='7'>
                                        +7
                                      </option>
                                      <option data-countryCode='KE' value='254'>
                                        +254
                                      </option>
                                      <option data-countryCode='KI' value='686'>
                                        +686
                                      </option>
                                      <option data-countryCode='KP' value='850'>
                                        +850
                                      </option>
                                      <option data-countryCode='KR' value='82'>
                                        +82
                                      </option>
                                      <option data-countryCode='KW' value='965'>
                                        +965
                                      </option>
                                      <option data-countryCode='KG' value='996'>
                                        +996
                                      </option>
                                      <option data-countryCode='LA' value='856'>
                                        +856
                                      </option>
                                      <option data-countryCode='LV' value='371'>
                                        +371
                                      </option>
                                      <option data-countryCode='LB' value='961'>
                                        +961
                                      </option>
                                      <option data-countryCode='LS' value='266'>
                                        +266
                                      </option>
                                      <option data-countryCode='LR' value='231'>
                                        +231
                                      </option>
                                      <option data-countryCode='LY' value='218'>
                                        +218
                                      </option>
                                      <option data-countryCode='LI' value='417'>
                                        +417
                                      </option>
                                      <option data-countryCode='LT' value='370'>
                                        +370
                                      </option>
                                      <option data-countryCode='LU' value='352'>
                                        +352
                                      </option>
                                      <option data-countryCode='MO' value='853'>
                                        +853
                                      </option>
                                      <option data-countryCode='MK' value='389'>
                                        +389
                                      </option>
                                      <option data-countryCode='MG' value='261'>
                                        +261
                                      </option>
                                      <option data-countryCode='MW' value='265'>
                                        +265
                                      </option>
                                      <option data-countryCode='MY' value='60'>
                                        +60
                                      </option>
                                      <option data-countryCode='MV' value='960'>
                                        +960
                                      </option>
                                      <option data-countryCode='ML' value='223'>
                                        +223
                                      </option>
                                      <option data-countryCode='MT' value='356'>
                                        +356
                                      </option>
                                      <option data-countryCode='MH' value='692'>
                                        +692
                                      </option>
                                      <option data-countryCode='MQ' value='596'>
                                        +596
                                      </option>
                                      <option data-countryCode='MR' value='222'>
                                        +222
                                      </option>
                                      <option data-countryCode='YT' value='269'>
                                        +269
                                      </option>
                                      <option data-countryCode='MX' value='52'>
                                        +52
                                      </option>
                                      <option data-countryCode='FM' value='691'>
                                        +691
                                      </option>
                                      <option data-countryCode='MD' value='373'>
                                        +373
                                      </option>
                                      <option data-countryCode='MC' value='377'>
                                        +377
                                      </option>
                                      <option data-countryCode='MN' value='976'>
                                        +976
                                      </option>
                                      <option data-countryCode='MS' value='1664'>
                                        +1664
                                      </option>
                                      <option data-countryCode='MA' value='212'>
                                        +212
                                      </option>
                                      <option data-countryCode='MZ' value='258'>
                                        +258
                                      </option>
                                      <option data-countryCode='MN' value='95'>
                                        +95
                                      </option>
                                      <option data-countryCode='NA' value='264'>
                                        +264
                                      </option>
                                      <option data-countryCode='NR' value='674'>
                                        +674
                                      </option>
                                      <option data-countryCode='NP' value='977'>
                                        +977
                                      </option>
                                      <option data-countryCode='NL' value='31'>
                                        +31
                                      </option>
                                      <option data-countryCode='NC' value='687'>
                                        +687
                                      </option>
                                      <option data-countryCode='NZ' value='64'>
                                        +64
                                      </option>
                                      <option data-countryCode='NI' value='505'>
                                        +505
                                      </option>
                                      <option data-countryCode='NE' value='227'>
                                        +227
                                      </option>
                                      <option data-countryCode='NG' value='234'>
                                        +234
                                      </option>
                                      <option data-countryCode='NU' value='683'>
                                        +683
                                      </option>
                                      <option data-countryCode='NF' value='672'>
                                        +672
                                      </option>
                                      <option data-countryCode='NP' value='670'>
                                        +670
                                      </option>
                                      <option data-countryCode='NO' value='47'>
                                        +47
                                      </option>
                                      <option data-countryCode='OM' value='968'>
                                        +968
                                      </option>
                                      <option data-countryCode='PW' value='680'>
                                        +680
                                      </option>
                                      <option data-countryCode='PA' value='507'>
                                        +507
                                      </option>
                                      <option data-countryCode='PG' value='675'>
                                        +675
                                      </option>
                                      <option data-countryCode='PY' value='595'>
                                        +595
                                      </option>
                                      <option data-countryCode='PE' value='51'>
                                        +51
                                      </option>
                                      <option data-countryCode='PH' value='63'>
                                        +63
                                      </option>
                                      <option data-countryCode='PL' value='48'>
                                        +48
                                      </option>
                                      <option data-countryCode='PT' value='351'>
                                        +351
                                      </option>
                                      <option data-countryCode='PR' value='1787'>
                                        +1787
                                      </option>
                                      <option data-countryCode='QA' value='974'>
                                        +974
                                      </option>
                                      <option data-countryCode='RE' value='262'>
                                        +262
                                      </option>
                                      <option data-countryCode='RO' value='40'>
                                        +40
                                      </option>
                                      <option data-countryCode='RU' value='7'>
                                        +7
                                      </option>
                                      <option data-countryCode='RW' value='250'>
                                        +250
                                      </option>
                                      <option data-countryCode='SM' value='378'>
                                        +378
                                      </option>
                                      <option data-countryCode='ST' value='239'>
                                        +239
                                      </option>
                                      <option data-countryCode='SA' value='966'>
                                        +966
                                      </option>
                                      <option data-countryCode='SN' value='221'>
                                        +221
                                      </option>
                                      <option data-countryCode='CS' value='381'>
                                        +381
                                      </option>
                                      <option data-countryCode='SC' value='248'>
                                        +248
                                      </option>
                                      <option data-countryCode='SL' value='232'>
                                        +232
                                      </option>
                                      <option data-countryCode='SG' value='65'>
                                        +65
                                      </option>
                                      <option data-countryCode='SK' value='421'>
                                        +421
                                      </option>
                                      <option data-countryCode='SI' value='386'>
                                        +386
                                      </option>
                                      <option data-countryCode='SB' value='677'>
                                        +677
                                      </option>
                                      <option data-countryCode='SO' value='252'>
                                        +252
                                      </option>
                                      <option data-countryCode='ZA' value='27'>
                                        +27
                                      </option>
                                      <option data-countryCode='ES' value='34'>
                                        +34
                                      </option>
                                      <option data-countryCode='LK' value='94'>
                                        +94
                                      </option>
                                      <option data-countryCode='SH' value='290'>
                                        +290
                                      </option>
                                      <option data-countryCode='KN' value='1869'>
                                        +1869
                                      </option>
                                      <option data-countryCode='SC' value='1758'>
                                        +1758
                                      </option>
                                      <option data-countryCode='SD' value='249'>
                                        +249
                                      </option>
                                      <option data-countryCode='SR' value='597'>
                                        +597
                                      </option>
                                      <option data-countryCode='SZ' value='268'>
                                        +268
                                      </option>
                                      <option data-countryCode='SE' value='46'>
                                        +46
                                      </option>
                                      <option data-countryCode='CH' value='41'>
                                        +41
                                      </option>
                                      <option data-countryCode='SI' value='963'>
                                        +963
                                      </option>
                                      <option data-countryCode='TW' value='886'>
                                        +886
                                      </option>
                                      <option data-countryCode='TJ' value='7'>
                                        +7
                                      </option>
                                      <option data-countryCode='TH' value='66'>
                                        +66
                                      </option>
                                      <option data-countryCode='TG' value='228'>
                                        +228
                                      </option>
                                      <option data-countryCode='TO' value='676'>
                                        +676
                                      </option>
                                      <option data-countryCode='TT' value='1868'>
                                        +1868
                                      </option>
                                      <option data-countryCode='TN' value='216'>
                                        +216
                                      </option>
                                      <option data-countryCode='TR' value='90'>
                                        +90
                                      </option>
                                      <option data-countryCode='TM' value='7'>
                                        +7
                                      </option>
                                      <option data-countryCode='TM' value='993'>
                                        +993
                                      </option>
                                      <option data-countryCode='TC' value='1649'>
                                        +1649
                                      </option>
                                      <option data-countryCode='TV' value='688'>
                                        +688
                                      </option>
                                      <option data-countryCode='UG' value='256'>
                                        +256
                                      </option>
                                      <option data-countryCode='UZ' value='7'>
                                        +7
                                      </option>
                                      <option data-countryCode='VU' value='678'>
                                        +678
                                      </option>
                                      <option data-countryCode='VA' value='379'>
                                        +379
                                      </option>
                                      <option data-countryCode='VE' value='58'>
                                        +58
                                      </option>
                                      <option data-countryCode='VN' value='84'>
                                        +84
                                      </option>
                                      <option data-countryCode='VG' value='84'>
                                        +1284
                                      </option>
                                      <option data-countryCode='VI' value='84'>
                                        +1340
                                      </option>
                                      <option data-countryCode='WF' value='681'>
                                        +681
                                      </option>
                                      <option data-countryCode='YE' value='969'>
                                        (+969
                                      </option>
                                      <option data-countryCode='YE' value='967'>
                                        (+967
                                      </option>
                                      <option data-countryCode='ZM' value='260'>
                                        +260
                                      </option>
                                      <option data-countryCode='ZW' value='263'>
                                        +263
                                      </option>
                                    </optgroup>
                                  </select>
                                </span>
                              </div>
                              <input
                                type='text'
                                id='mobilenumber'
                                className='form-control form-control-sm'
                                placeholder='Mobile Number'
                                aria-describedby='mobile-number-group'
                              />
                            </div>
                            <div className='text-right d-flex justify-content-end'>
                              <button type='button' className='btn px-2 edit-details'>
                                Edit Number
                              </button>
                              <button type='button' className='btn px-2 cancel text-danger'>
                                Cancel
                              </button>
                              <button type='button' className='btn px-2 save-details text-green'>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <form
                      method='get'
                      className='digit-group'
                      data-group-name='digits'
                      data-autosubmit='false'
                      autoComplete='off'
                    >
                      <div className='form-group col-12 p-0 m-0'>
                        <div className='d-flex justify-content-between mb-1'>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            id='digit-1'
                            name=''
                            data-next='digit-2'
                          />
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            id='digit-2'
                            name=''
                            data-next='digit-3'
                            data-previous='digit-1'
                          />
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            id='digit-3'
                            name=''
                            data-next='digit-4'
                            data-previous='digit-2'
                          />
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            id='digit-4'
                            name=''
                            data-next='digit-5'
                            data-previous='digit-3'
                          />
                        </div>
                      </div>
                      <div className='row justify-content-between'>
                        <div className='col-auto mb-2'>
                          <button
                            type='button'
                            className='btn px-2'
                            id='starttimeragain'
                            data-dismiss='modal'
                            data-target='#verify-otp-modal'
                            data-toggle='modal'
                          >
                            Resend (use OTP 1234)
                          </button>
                        </div>
                        <div className='col-auto mb-2'>
                          <div id='timer' className='btn px-2' data-timer='02:00'></div>
                        </div>
                      </div>
                      <button type='submit' className='btn btn-deep-primary btn-block rounded-0'>
                        Continue
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* indoor outdoor Modal */}
        <div
          className='modal px-0'
          id='indoor-outdoor-modal'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='indoor-outdoor-modalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-xl' role='document'>
            <div className='modal-content border-0'>
              <div className='modal-body'>
                <div className='row'>
                  <div className='col-12 modal-body-right-content pt-4 px-sm-5 px-2'>
                    <button
                      ref={myRefname3}
                      type='button'
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                    >
                      <span aria-hidden='true'>×</span>
                    </button>
                    <div className='card-body py-0'>
                      <ul
                        className='nav nav-pills common-tabs2 mb-3 justify-content-center'
                        id='indoor-outdoor-tab'
                        role='tablist'
                        data-getelement='#modal-body-left-content'
                      >
                        <li className='nav-item px-0'>
                          <a
                            className='nav-link h5 px-4 active'
                            id='indoor-tab'
                            data-toggle='pill'
                            href='#indoor'
                            role='tab'
                            aria-controls='indoor'
                            aria-selected='true'
                          >
                            INDOOR Active sectoin
                          </a>
                        </li>
                        <li className='nav-item px-0'>
                          <a
                            className='nav-link h5 px-4'
                            id='outdoor-tab'
                            data-toggle='pill'
                            href='#outdoor'
                            role='tab'
                            aria-controls='outdoor'
                            aria-selected='false'
                          >
                            OUTDOOR
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-8 col-12 mx-auto px-5 py-3'>
                    <div className='tab-content px-sm-3 px-2' id='indoor-outdoor-tabContent'>
                      <div
                        className='tab-pane fade show active'
                        id='indoor'
                        role='tabpanel'
                        aria-labelledby='indoor-tab'
                      >
                        <div className='col-lg-12 d-flex align-items-center justify-content-center'>
                          <ImageGallery items={images} />;
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='outdoor'
                        role='tabpanel'
                        aria-labelledby='outdoor-tab'
                      >
                        <div className='col-lg-12 d-flex align-items-center justify-content-center'>
                          <ImageGallery items={images1} />;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ==================================== */}

        <Modal
          show={show}
          onHide={handleClose}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Body className='m-0 p-0'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='product-item-show-modalTitle'>
                  {warehouseId}
                </h5>
                <button
                  onClick={() => setShow(false)}
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span className='text-warning fa' aria-hidden='true'>
                    &times;
                  </span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='card custom-card-overlay2 mx-2'>
                  <div className='img-holder card-img'>
                    <img
                      src={
                        items &&
                        items.warehouseDetailPage &&
                        items.warehouseDetailPage.warehouseImagesInfo &&
                        items.warehouseDetailPage.warehouseImagesInfo.coverImage.url
                      }
                      alt=''
                      className='img-fluid w-100'
                    />
                  </div>
                  <div className='card-img-overlay d-flex align-items-end'>
                    <div className='card-body px-0 d-flex justify-content-center'>
                      <btn
                        onClick={() => {
                          if (!data.authenticated) {
                            addToast('Please Login', {
                              appearance: 'error',
                              autoDismiss: true,
                            });
                            return 0;
                          }
                          setwaresheet({
                            imgUrl:
                              items &&
                              items.warehouseDetailPage &&
                              items.warehouseDetailPage.warehouseImagesInfo &&
                              items.warehouseDetailPage.warehouseImagesInfo.coverImage.url,
                            note: '',
                          });
                          if (loadwaresheets.length > 0) {
                            setShow(false);
                            setSaveWaresheet(true);
                          } else {
                            setShow(false);

                            setModalIsOpen1(true);
                          }
                        }}
                        className='btn-light card-bookmark px-3 py-2 rounded'
                      >
                        <p style={{ cursor: 'pointer' }} className='card-title mb-0'>
                          <i
                            onClick={() => {
                              setwaresheet({
                                imgUrl:
                                  items &&
                                  items.warehouseDetailPage &&
                                  items.warehouseDetailPage.warehouseImagesInfo &&
                                  items.warehouseDetailPage.warehouseImagesInfo.coverImage.url,
                                note: '',
                              });
                            }}
                            className='fas fa-bookmark'
                          />{' '}
                          Save this image
                        </p>
                      </btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ===============  setSaveWaresheet  ===================== */}

        <Modal
          show={saveWaresheet}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Body className='m-0 p-0'>
            <div className='modal-content border-0'>
              <div className='modal-body py-0'>
                <div className='row px-3'>
                  <div className='modal-body-right-content w-100 py-lg-4 my-1 p-sm-4 p-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <button
                          onClick={() => setSaveWaresheet(!saveWaresheet)}
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                        <div className=''>
                          <h5 className='mb-4 modal-title'>Save to My Waresheet</h5>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-8 mx-auto py-3'>
                          <div className='card border'>
                            <div className='img-holder'>
                              <img
                                className='img-fluid w-100'
                                src={waresheet.imgUrl}
                                alt='waresheet'
                              />
                            </div>
                            <div className='card-body'>
                              <h6>Warehouse {warehouseId}</h6>
                              <p className='mb-0'>
                                <i className='fas fa-map-marker-alt text-primary'></i>{' '}
                                <span>
                                  {' '}
                                  {items &&
                                    items.warehouseDetailPage &&
                                    items.warehouseDetailPage.warehouseContactDetailInfo &&
                                    items.warehouseDetailPage.warehouseContactDetailInfo.address &&
                                    items.warehouseDetailPage.warehouseContactDetailInfo.address
                                      .district +
                                      ',' +
                                      items.warehouseDetailPage.warehouseContactDetailInfo.address
                                        .city}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-8 py-3'>
                          <form>
                            <div className='dropdown create-new-waresheet-dropdown'>
                              <button
                                className='btn btn-deep-primary btn-block text-left dropdown-toggle'
                                type='button'
                                id='dropdownMenu1'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                {selectedOption?.label}
                                {selectedOption === null ? 'Select' : ''}
                              </button>
                              <div
                                style={{
                                  padding: '0px',
                                }}
                                className='dropdown-menu'
                                aria-labelledby='dropdownMenu1'
                              >
                                {/* <h6 className="dropdown-header">{selectedOption}</h6> */}
                                <div className='dropdown-item p-0 m-0'>
                                  <select
                                    onChange={(e) => {
                                      // eslint-disable-next-line
                                      let index = loadwaresheets.findIndex(
                                        (x) => parseInt(x.id) === parseInt(e.target.value),
                                      );
                                      setselectedOption({
                                        label: loadwaresheets[index].waresheetName,
                                        value: loadwaresheets[index].id,
                                      });
                                      setNoteError(null);
                                    }}
                                    multiple
                                    size='3'
                                    className='form-control form-control-sm form-control form-control-sm-lg'
                                    id='exampleFormControlSelect2'
                                  >
                                    {loadwaresheets && loadwaresheets.length > 0
                                      ? loadwaresheets.map((value, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={value.id}
                                              selected={value.makeItDefault === true}
                                            >
                                              {value.waresheetName}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </div>
                                <div className='dropdown-item d-flex align-items-center p-0 m-0'>
                                  <i
                                    onClick={() => {
                                      if (ware !== '') createnewwaresheet(ware);
                                    }}
                                    className='fas fa-plus mx-2'
                                  ></i>
                                  <input
                                    value={ware}
                                    onChange={(e) => {
                                      setware(e.target.value);
                                    }}
                                    type='text'
                                    className='form-control form-control-sm'
                                    id='exampleFormControlInput1'
                                    placeholder='Create New'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-group mt-3'>
                              <textarea
                                onChange={(e) => {
                                  setwaresheet({
                                    ...waresheet,
                                    note: e.target.value,
                                  });
                                  setNoteError(null);
                                }}
                                className='form-control form-control-sm rounded-md'
                                id='exampleFormControlTextarea1'
                                rows='9'
                                placeholder='Add notes, what do you like about this Warehouse?'
                              ></textarea>
                              <span className='text-danger'>{noteError}</span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {console.log('form-80 data')} */}
                {/* {console.log(Data)} */}
                {/* {console.log('form-80 data')} */}
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </Modal.Body>
        </Modal>

        {/* // </div> */}
      </Layout>
    </>
  );
};

export default WarehouseDetails;
