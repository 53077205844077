import React, { useState, useEffect, createContext } from 'react';
import {
  changeWarehouseStatus,
  // updatePhoto,'
  isLoading,
  updatePhotoNew,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosauth from '../../../api/axios-auth';
import { CardLoader } from '../../helper/CustomLoader';
import { useHistory } from 'react-router-dom';
export const UserContext = createContext();
const WarehousePhotoForm = ({ warehouseId, viewMood, slug, closeErrorMessage }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const history = useHistory();
  const [imgId, setImgId] = useState(null);
  const [coverError, setCoverError] = useState('');
  const [indoorError, setIndoorError] = useState('');
  const [outdoorError, setOutdoorError] = useState('');
  // const [coverView, setCoverView] = useState(null);
  // const [coverViewDoor, setCoverViewDoor] = useState([]);
  // const [indoorViewDoor, setIndoorViewDoor] = useState([]);
  // const [outdoorViewDoor, setOutdoorViewDoor] = useState([]);
  const [inDoorView, setInDoorView] = useState([1]);
  const [outDoorView, setOutDoorView] = useState([1]);
  const [inDoor, setInDoor] = useState([]);
  const [outDoor, setOutDoor] = useState([]);

  const [indoorPhoto, setindoorPhoto] = useState([]);
  const [outdoorPhoto, setoutdoorPhoto] = useState([]);

  const [Photo, setPhoto] = useState({
    cover: '',
    photo: {
      indoor: [],
      outdoor: [],
    },
  });

  useEffect(() => {
    if (
      data?.singleFormData &&
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRes?.warehouseImages !== null &&
      data?.singleFormData?.warehouseRes?.warehouseImages !== undefined
    ) {
      setInDoorView(
        data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.indoor?.length > 0
          ? [
              ...Array(
                data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.indoor?.length,
              ),
            ]
          : [0],
      );
      setOutDoorView(
        data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.outdoor?.length > 0
          ? [
              ...Array(
                data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.outdoor?.length,
              ),
            ]
          : [0],
      );
      setPhoto({
        cover: data?.singleFormData?.warehouseRes?.warehouseImages[0]?.cover,
        photo: {
          indoor: data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.indoor,
          outdoor: data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.outdoor,
        },
      });
    }
    if (
      data?.singleFormData &&
      data?.singleFormData?.remarks === 1 &&
      data?.singleFormData?.warehouseRes?.warehouseImagesRemarks !== null &&
      data?.singleFormData?.warehouseRes?.warehouseImagesRemarks !== undefined
    ) {
      setInDoorView(
        data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsIndoors?.indoor[0]?.value
          ?.length > 0
          ? data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsIndoors?.indoor[0]?.value
          : [0],
      );
      setOutDoorView(
        data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsOutdoors?.outdoor[0]?.value
          ?.length > 0
          ? data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsOutdoors?.outdoor[0]
              ?.value
          : [0],
      );
      setPhoto({
        cover:
          data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsCoverImage?.coverImg
            ?.value,
        photo: {
          indoor:
            data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsIndoors?.indoor[0]
              ?.value,
          outdoor:
            data?.singleFormData?.warehouseRes?.warehouseImagesRemarks?.whsOutdoors?.outdoor[0]
              ?.value,
        },
      });
    }
    console.log(outDoorView, '**TTTTTTTT**');
  }, [data?.singleFormData]);

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse/${warehouseId}/form-80`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  const coverHandle = (e) => {
    // setCoverViewDoor([e.target.files[0]]);
    const dataCover = new FormData();
    dataCover.append('image', e.target.files[0]);
    dataCover.append('type', 'coverImage');
    dataCover.append('warehouse', warehouseId);
    dataCover.append('warehouseImage', imgId);
    dispatch(isLoading(true));
    axiosauth
      .post('/api/v1/warehouseimage', dataCover)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          setCoverError('');
          // setCoverView(res.data);
          setPhoto((prevState) => ({
            ...prevState,
            cover: res.data,
          }));
          dispatch(isLoading(false));
        } else {
          // console.log('LandlordFile Action Error');
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        // console.log('here', error);
        dispatch(isLoading(false));
      });
  };

  const inDoorHandle = (e, index) => {
    const updatedArray = { ...inDoor };
    updatedArray[index] = e.target.files[0];
    // setIndoorViewDoor([e.target.files[0]]);
    setInDoor(updatedArray);
    const dataInDoor = new FormData();
    dataInDoor.append('image', e.target.files[0]);
    dataInDoor.append('type', 'indoor');
    dataInDoor.append('warehouseImage', imgId);
    dataInDoor.append('warehouse', warehouseId);
    dispatch(isLoading(true));
    axiosauth
      .post('/api/v1/warehouseimage', dataInDoor)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          setIndoorError('');
          setindoorPhoto([...indoorPhoto, res.data]);
          setPhoto((prevState) => {
            const updatedIndoorPhotos = [...prevState.photo.indoor];
            updatedIndoorPhotos[index] = res.data;

            return {
              ...prevState,
              photo: {
                ...prevState.photo,
                indoor: updatedIndoorPhotos,
              },
            };
          });
          dispatch(isLoading(false));
        } else {
          // console.log('LandlordFile Action Error');
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        // console.log('here', error);
        dispatch(isLoading(false));
      });
  };

  const outDoorHandle = (e, index) => {
    const updatedArray = { ...outDoor };
    updatedArray[index] = e.target.files[0];
    setOutDoor(updatedArray);
    // setOutdoorViewDoor([e.target.files[0]]);
    const dataOutdoor = new FormData();
    dataOutdoor.append('image', e.target.files[0]);
    dataOutdoor.append('type', 'outdoor');
    dataOutdoor.append('warehouseImage', imgId);
    dataOutdoor.append('warehouse', warehouseId);
    dispatch(isLoading(true));
    axiosauth
      .post('/api/v1/warehouseimage', dataOutdoor)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          setOutdoorError('');
          setoutdoorPhoto([...outdoorPhoto, res.data]);

          setPhoto((prevState) => {
            const updatedIndoorPhotos = [...prevState.photo.outdoor];
            updatedIndoorPhotos[index] = res.data;

            return {
              ...prevState,
              photo: {
                ...prevState.photo,
                outdoor: updatedIndoorPhotos,
              },
            };
          });
          dispatch(isLoading(false));
        } else {
          // console.log('LandlordFile Action Error');
          isLoading(false);
        }
      })
      .catch((error) => {
        // console.log('here', error);
        isLoading(false);
      });
  };

  useEffect(() => {
    // console.log(data.singleFormData?.warehouseImagesInfo, '*******alpha action abcd********');
    if (data.singleFormData?.warehouseImagesInfo !== undefined) {
      setImgId(data.singleFormData.warehouseImagesInfo?.id);

      if (data.singleFormData.warehouseImagesInfo?.coverImage) {
        // setCoverViewDoor([data.singleFormData.warehouseImagesInfo.coverImage.url]);
        // setCoverView(data.singleFormData.warehouseImagesInfo.coverImage.url);
      }

      if (data.singleFormData.warehouseImagesInfo?.indoorImage) {
        setInDoor({ url: data.singleFormData.warehouseImagesInfo.indoorImage });
      }

      if (data.singleFormData.warehouseImagesInfo?.outdoorImage) {
        setOutDoor([data.singleFormData.warehouseImagesInfo.outdoorImage]);
      }
    } else {
    }
  }, [data.singleFormData.warehouseImagesInfo]);

  const handleDeletOutDoorView = (id) => {
    const updateOutdoorImage = Photo?.photo?.outdoor?.filter((_, index) => index !== id);
    const outDoorViewVal = outDoorView.filter((_, index) => index !== id);

    setPhoto((prevState) => {
      return {
        ...prevState,
        photo: {
          ...prevState.photo,
          outdoor: updateOutdoorImage,
        },
      };
    });
    setOutDoorView(outDoorViewVal);
  };

  const handleDeletInDoorView = (id) => {
    const updateIndoorImage = Photo?.photo?.indoor?.filter((_, index) => index !== id);
    setPhoto((prevState) => {
      return {
        ...prevState,
        photo: {
          ...prevState.photo,
          indoor: updateIndoorImage,
        },
      };
    });
    const inDoorViewVal = inDoorView.filter((_, index) => index !== id);
    setInDoorView(inDoorViewVal);
  };

  let enableCheckImage = () => {
    /*indoor outdoor logic*/

    if (
      inDoorView.length !== Photo?.photo?.indoor?.filter((elem) => elem.length > 0).length &&
      outDoorView.length !== Photo?.photo?.outdoor?.filter((elem) => elem.length > 0).length
    ) {
      setIndoorError('Please Choose Indoor Photo');
      setOutdoorError('Please Choose Outdoor Photo');
      return;
    }
    if (
      inDoorView.length !== Photo?.photo?.indoor?.filter((elem) => elem.length > 0).length ||
      outDoorView.length !== Photo?.photo?.outdoor?.filter((elem) => elem.length > 0).length
    ) {
      if (inDoorView.length !== Photo?.photo?.indoor?.filter((elem) => elem.length > 0).length) {
        setIndoorError('Please Choose Indoor Photo');
        return;
      }
      if (outDoorView.length !== Photo?.photo?.outdoor?.filter((elem) => elem.length > 0).length) {
        setOutdoorError('Please Choose Outdoor Photo');
        return;
      }
    }

    /*indoor outdoor logic*/

    if (Photo.cover === '') {
      setCoverError('Please choose Cover Image');
      return;
    }

    if (Photo.photo.indoor.length === 0) {
      alert('Indoor empty');
      setIndoorError('Please choose Indoor Image');
      return;
    }
    if (Photo.photo.outdoor.length === 0) {
      alert('Outdoor empty');
      setOutdoorError('Please choose  Outdoor Image');
      return;
    }
    // slug, warehouseId;
    // console.log('hello i am running');
    let updatePhotoData = {
      files: {
        ...Photo,
      },
      warehouseId: warehouseId,
      slug: slug,
    };

    dispatch(updatePhotoNew(updatePhotoData));
  };
  return (
    <>
      {data?.isLoading ? (
        <CardLoader />
      ) : (
        <div className='row align-items-center pb-3 mx-0'>
          <div className='col-12'>
            <form onSubmit={enableCheckImage}>
              <div className='row bg-white rounded mx-0 col-xxxl-11'>
                <div className='col-12'>
                  <h5 className='py-3 mb-3 border-bottom'>Warehouse Cover Photo</h5>
                </div>
                <div className='col-12'>
                  <div className='cards'>
                    <div className='col-xl-6 float-sm-left'>
                      <div className='img-holder size-200px'>
                        <img
                          className='w-100 h-100 img-fluid rounded overflow-hideen'
                          id='imageResult'
                          src='/assets/images/upload-bg.png'
                          alt=''
                        />
                      </div>
                      <div className='card-img-overlay size-200px d-flex justify-content-center align-items-center'>
                        <div className='card-text'>
                          <input
                            onChange={(e) => coverHandle(e)}
                            id='upload-image-preview1'
                            type='file'
                            className='form-control border-0'
                            hidden
                            disabled={viewMood}
                          />
                          <label
                            id='upload-label'
                            htmlFor='upload-image-preview1'
                            className='font-weight-light text-muted'
                          ></label>
                          <div className='input-group-append'>
                            <label
                              htmlFor='upload-image-preview1'
                              className='btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4'
                            >
                              <div>
                                <img src='/assets/images/icons/upload-icon-deep-blue.png' alt='' />
                              </div>
                              Upload image
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='text-danger'>{coverError}</div>
                    </div>
                    <div className='col-xl-3 float-sm-right'>
                      {Photo.cover ? (
                        <img
                          className='w-100 h-100 img-fluid rounded overflow-hideen'
                          id='imageResult'
                          src={Photo.cover}
                          alt=''
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    {/*End */}
                  </div>
                </div>
              </div>

              {/* ########################################## */}

              <div className='row bg-white pt-3 rounded mx-0 col-xxxl-11'>
                <div className='col-12'>
                  <h5 className='py-3 mb-3 border-bottom'>Warehouse Indoor Photos</h5>
                </div>
                {inDoorView !== undefined &&
                  inDoorView.map((c, index) => {
                    return (
                      <div key={index} className='col-xl-12 col-lg-12 col-sm-6 mb-2'>
                        <div className='cards'>
                          <div className='col-xl-6 float-sm-left'>
                            <div className='img-holder size-200px'>
                              <img
                                className='w-100 h-100 img-fluid rounded overflow-hideen'
                                id='imageResult'
                                src='/assets/images/upload-bg.png'
                                alt=''
                              />
                            </div>
                            <div className='card-img-overlay size-200px d-flex justify-content-center align-items-center'>
                              <div className='card-text'>
                                <input
                                  type='file'
                                  onChange={(e) => inDoorHandle(e, index)}
                                  className='form-control border-0'
                                  id={`upload-image-preview115${index}`}
                                  hidden
                                  disabled={viewMood}
                                />
                                <label
                                  id='upload-label'
                                  htmlFor={`upload-image-preview115${index}`}
                                  className='font-weight-light text-muted'
                                ></label>
                                <div className='input-group-append'>
                                  <label
                                    htmlFor={`upload-image-preview115${index}`}
                                    className='btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4'
                                  >
                                    <div>
                                      <img
                                        src='/assets/images/icons/upload-icon-deep-blue.png'
                                        alt=''
                                      />
                                    </div>
                                    Upload image
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-3 float-sm-right mb-5'>
                            {/* {console.log(Photo, "||Photo||")} */}
                            <img
                              className='w-100 h-100 img-fluid rounded overflow-hideen'
                              id='imageResult'
                              src={Photo?.photo?.indoor[index]}
                              alt=''
                            />
                            {viewMood === true ? (
                              <></>
                            ) : index !== 0 ? (
                              <span
                                onClick={() => handleDeletInDoorView(index)}
                                className='ml-4 cursors float-right my-2'
                              >
                                {/* {index} */}
                                <DeleteIcon />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className='text-danger pr-4 text-end'>{indoorError}</div>
                <div className='col-12 mt-4'>
                  {!viewMood && (
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        <button
                          type='button'
                          onClick={() => {
                            setInDoorView([...inDoorView, inDoorView.length + 1]);
                            // setIndoorViewDoor([]);
                          }}
                          className='btn text-deep-blue text-uppercase font-weight-bold'
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row bg-white pt-3 rounded mx-0 col-xxxl-11'>
                <div className='col-12'>
                  <h5 className='py-3 mb-3 border-bottom'>Warehouse Outdoor Photos</h5>
                </div>
                {outDoorView !== undefined &&
                  outDoorView.map((c, index) => {
                    return (
                      <div key={index} className='col-xl-12 col-lg-12 col-sm-6 mb-2'>
                        <div className='cards'>
                          <div className='col-xl-6 float-sm-left'>
                            <div className='img-holder size-200px'>
                              <img
                                className='w-100 h-100 img-fluid rounded overflow-hideen'
                                id='imageResult'
                                src='/assets/images/upload-bg.png'
                                alt=''
                              />
                            </div>
                            <div className='card-img-overlay size-200px d-flex justify-content-center align-items-center'>
                              <div className='card-text'>
                                <input
                                  onChange={(e) => outDoorHandle(e, index)}
                                  id={`upload-image-previewout${index}`}
                                  type='file'
                                  className='form-control border-0'
                                  hidden
                                  disabled={viewMood}
                                />
                                <label
                                  id='upload-label'
                                  htmlFor={`upload-image-previewout${index}`}
                                  className='font-weight-light text-muted'
                                ></label>
                                <div className='input-group-append'>
                                  <label
                                    htmlFor={`upload-image-previewout${index}`}
                                    className='btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4'
                                  >
                                    <div>
                                      <img
                                        src='/assets/images/icons/upload-icon-deep-blue.png'
                                        alt=''
                                      />
                                    </div>
                                    Upload image
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-3 float-sm-right mb-5'>
                            <img
                              className='w-100 h-100 img-fluid rounded overflow-hideen'
                              id='imageResult'
                              src={Photo?.photo?.outdoor[index]}
                              alt=''
                            />
                            {viewMood === true ? (
                              <></>
                            ) : index !== 0 ? (
                              <span
                                onClick={() => handleDeletOutDoorView(index)}
                                className='ml-4 cursors float-right my-2'
                              >
                                {/* {index} */}
                                <DeleteIcon />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className='text-danger pr-4 text-end'>{outdoorError}</div>
                <div className='col-12 mt-4'>
                  {!viewMood && (
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        <button
                          type='button'
                          onClick={() => {
                            setOutDoorView([...outDoorView, outDoorView.length + 1]);
                            // setOutdoorViewDoor([]);
                          }}
                          className='btn text-deep-blue text-uppercase font-weight-bold'
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-12 mt-4'>
                  {!viewMood && (
                    <div className={'row justify-content-end'}>
                      <div className='col-auto'>
                        <button
                          type='submit'
                          className='btn btn-deep-blue add-className remove-className my-2'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          {/* {console.log('debugging')} */}
          {/* {console.log(inDoorView)} */}
          {/* {console.log(outDoorView)} */}
          {/* {console.log('debugging')} */}
        </div>
      )}
    </>
  );
};

export default WarehousePhotoForm;
