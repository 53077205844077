import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './warehouseList.css';

const ImageSliderTesting = ({ setPopup, popup }) => {
  const items = useSelector((state) => state.WAREHOUSEINFO);
  const [imageCount, setImageCount] = useState(0);

  const warehouseImage =
    items?.warehouseDetailPage?.remarks === 1
      ? items?.warehouseDetailPage?.warehouseImages !== undefined
        ? [
            items?.warehouseDetailPage?.warehouseImages?.whsCoverImage?.coverImg?.value,
            ...items?.warehouseDetailPage?.warehouseImages?.whsIndoors?.indoor[0].value,
            ...items?.warehouseDetailPage?.warehouseImages?.whsOutdoors?.outdoor[0]?.value,
          ]
        : ''
      : items !== undefined &&
        items?.warehouseDetailPage !== undefined &&
        items?.warehouseDetailPage !== null &&
        items?.warehouseDetailPage?.warehouseImages !== undefined
      ? items?.warehouseDetailPage?.warehouseImages?.map((elem, index) => {
          return [elem?.cover, ...elem?.photo?.indoor, ...elem?.photo?.outdoor];
        })
      : [
          'https://img.freepik.com/premium-vector/window-operating-system-error-warning-dialog-window-popup-message-with-system-failure-flat-design_812892-54.jpg',
        ];

  const ImageList =
    items?.warehouseDetailPage?.remarks === 1 ? [...warehouseImage] : [...warehouseImage[0]];

  const Increment = () => {
    if (imageCount < ImageList.length - 1) {
      setImageCount(imageCount + 1);
    }
  };

  const Decrement = () => {
    if (imageCount > 0) {
      setImageCount(imageCount - 1);
    }
  };
  return (
    <>
      {/* <h5 className='backButton  text-dark'>
        <i
          onClick={() => {
            setPopup(false);
            document.body.classList.remove('no-scroll');
          }}
          className='fas fa-chevron-left mr-3 cursorPointer'
        ></i>
        Back to details page
      </h5> */}

      {/* <div className='slider-container '>
        <div class='gallery position-absolute mt-4'>
          {ImageList !== undefined && Object.keys(ImageList).length > 0
            ? ImageList?.map((elem, index) => {
                return (
                  <div class='image-container' key={index}>
                    <img src={elem} className='imgClass' />
                  </div>
                );
              })
            : ''}
        </div>
      </div> */}
      <img
        src='https://cdn-icons-png.flaticon.com/512/12373/12373420.png'
        class='position-fixed h3 text-secondary cursors crossIcon'
        onClick={() => {
          setPopup(false);
          document.body.classList.remove('no-scroll');
        }}
      />
      <div className='parent'>
        <div class='gallery mx-auto'>
          {ImageList !== undefined && Object.keys(ImageList).length > 0
            ? ImageList?.map((elem, index) => {
                return (
                  <div className={`image-container ${index % 2 == 0 ? 'tall' : ''}`}>
                    <img src={elem} />
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
};

export default ImageSliderTesting;
