import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AsyncImage } from 'loadable-image';
import { favoriteAdd, favoriteRemove } from '../../../store/actions/customer/favoriteAction';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import './main.css';
import './warehouseList.css';

const WarehouseList = ({ item, index, cartHandle, compareWhId }) => {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const [areatrue, setareatrue] = useState(false);
  const [sliderShow, setSliderShow] = useState(false);
  const [availabletrue, setavailabletrue] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  const { addToast } = useToasts();
  const {
    id,
    warehouseName,
    soldOut,
    type,
    category,
    warehouseImages,
    warehouseContacts,
    adminStatus,
  } = item;
  let abasement =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.floors &&
    item.storageSpaceInfo.floors.find((o) => o.floorType === 'basement');

  let basementTrue = abasement?.floorDimension?.length !== '';
  let agroundFloor =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.floors &&
    item.storageSpaceInfo.floors.find((o) => o.floorType === 'groundFloor');
  let groundFloorTrue = agroundFloor?.floorDimension?.length !== '';
  let afirstFloor =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.floors &&
    item.storageSpaceInfo.floors.find((o) => o.floorType === 'firstFloor');
  let firstFloorTrue = afirstFloor?.floorDimension?.length !== '';
  let asecondFloor =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.floors &&
    item.storageSpaceInfo.floors.find((o) => o.floorType === 'secondFloor');
  let secondFloorTrue = asecondFloor?.floorDimension?.length !== '';

  let abasement1 =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.availableSpaces &&
    item.storageSpaceInfo.availableSpaces.find((o) => o.availableSpaceType === 'basement');
  let basementTrue1 = abasement1?.availableSpaceDimension?.length !== '';
  let agroundFloor1 =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.availableSpaces &&
    item.storageSpaceInfo.availableSpaces.find((o) => o.availableSpaceType === 'groundFloor');
  let groundFloorTrue1 = agroundFloor1?.availableSpaceDimension?.length !== '';
  let afirstFloor1 =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.availableSpaces &&
    item.storageSpaceInfo.availableSpaces.find((o) => o.availableSpaceType === 'firstFloor');
  let firstFloorTrue1 = afirstFloor1?.availableSpaceDimension?.length !== '';
  let asecondFloor1 =
    item &&
    item.storageSpaceInfo &&
    item.storageSpaceInfo.availableSpaces &&
    item.storageSpaceInfo.availableSpaces.find((o) => o.availableSpaceType === 'secondFloor');
  let secondFloorTrue1 = asecondFloor1?.availableSpaceDimension?.length !== '';

  const addToFavorite = () => {
    // console.log("Add to favoruite add");

    if (data.authenticated && data.FAVORITEINFO.favoriteListIds.includes(id)) {
      dispatch(favoriteRemove(id, addToast));
    } else if (data.authenticated) {
      console.log();
      dispatch(favoriteAdd(id, addToast));
    } else {
      addToast('Please Login', { appearance: 'error', autoDismiss: true });
    }
  };
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  // Favorite compare

  const warehouseImage = warehouseImages?.map((elem, index) => {
    return [elem?.cover, elem?.photo?.indoor[0], elem?.photo?.outdoor[0]];
  });

  const ImageList = [...warehouseImage[0]];

  const Increment = () => {
    if (imageCount < ImageList.length - 1) {
      setImageCount(imageCount + 1);
    }
  };

  const Decrement = () => {
    if (imageCount > 0) {
      setImageCount(imageCount - 1);
    }
  };

  return (
    <div className='widthStyle paddingStyle mb-2'>
      <div
        className='card custom-warehouse-detail custom-shadow my-3'
        onMouseEnter={() => setSliderShow(true)}
        onMouseLeave={() => setSliderShow(false)}
        style={{ borderRadius: '0.25rem', border: 'none' }}
      >
        {soldOut && (
          <div style={{ left: '0', position: 'absolute', top: '0', zIndex: '1' }}>
            <button onClick={addToFavorite} className='px-2 py-1 text-white btn badge-warning m-2'>
              Sold Out
            </button>
          </div>
        )}
        <div
          className={`card-bookmark ${
            data.FAVORITEINFO?.favoriteListIds?.includes(id) ? 'active-book' : ''
          }`}
        >
          <button onClick={addToFavorite} className='px-2 pt-0 btn '>
            <i className={`fas fa-heart `}></i>
          </button>
        </div>
        <div className='card card-overlay' style={{ border: 'none' }}>
          <div className='img-holder card-img'>
            {adminStatus === 'Listed_and_Verified' ? (
              <div class='Cards' style={{ zIndex: '1000' }}>
                <div class='triangle'>
                  <div class='approved-text font-weight-bold'>Verified</div>
                </div>
              </div>
            ) : (
              ''
            )}

            {sliderShow
              ? imageCount !== 0 && (
                  <li
                    class='position-absolute'
                    style={{ top: '145px', left: '20px', zIndex: '99' }}
                  >
                    <i class='fas fa-chevron-left h4  cursors text-white' onClick={Decrement}></i>
                  </li>
                )
              : ''}
            <Link to={`/wh-detail/${id}`}>
              <AsyncImage
                style={{ height: '300px', objectFit: 'cover', width: '338px' }}
                src={ImageList[imageCount]}
                alt=''
                className='img-fluid w-100'
              />
            </Link>
            {sliderShow
              ? imageCount !== ImageList.length - 1 && (
                  <li class='position-absolute ' style={{ bottom: '120px', right: '20px' }}>
                    <i class='fas fa-chevron-right h4 text-white cursors' onClick={Increment}></i>
                  </li>
                )
              : ''}
          </div>
        </div>
      </div>
      <div className='px-2'>
        <h5 className='card-title mb-1'>{capitalizeWords(warehouseName)}</h5>
        <span className='text-gray'>
          {item &&
            item?.warehouseContactDetailInfo &&
            item?.warehouseContactDetailInfo?.address?.district +
              ', ' +
              item?.warehouseContactDetailInfo?.address?.state}
        </span>
        <li className='row justify-content-between'>
          <div className='col-4 font-heading'>Location:</div>
          <div className='col-6 text-gray px-0'>{`${warehouseContacts[0].district} , ${warehouseContacts[0].state}`}</div>
        </li>
        <li className='row'>
          <div className='col-6 font-heading'>Warehouse Category:</div>
          <div className='col-5 text-gray px-0'>{category?.categoryName}</div>
        </li>
        <li className='row'>
          <div className='col-6 font-heading'>Warehouse Type:</div>
          <div className='col-5 text-gray px-0'>{type?.type}</div>
        </li>
      </div>
    </div>
  );
};

export default WarehouseList;
